import { atom } from "recoil";

export const orderState = atom({
  key: "orderState",
  default: {},
});

export const orderDetailState = atom({
  key: "orderDetailState",
  default: {},
});

export default {
  orderState,
  orderDetailState,
};
