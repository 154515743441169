import React, {useState,useEffect,Link} from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useModalStore } from "store";
import SectionHeading from "./common/SectionHeading";
import ProductBox from "./home/ProductBox";
import TopSearch from "./home/TopSearch";
import ProductModal from "./modals/ProductModal";
import { useRecoilValue } from "recoil";
import { bannersListState } from "store/meta";
import {STORAGE_PATH} from "../constant"

const Index = ({ history }) => {
  const showModal = useModalStore((state) => state.showModal);
  const banners = useRecoilValue(bannersListState);

  const [currentPromos, setCurrentPromos] = useState([]);
  const [currentMenus, setCurrentMenus] = useState([]);
  const [shortcuts, setShortCuts] = useState([]);

  useEffect(() => {
    if (banners && banners.length > 0) {
      const specialPromos = banners.filter(banner => banner.type === 'SPECIAL_PROMO');
      if (specialPromos && specialPromos.length > 0) {
        setCurrentPromos(specialPromos);
      }
      const specialMenus = banners.filter(banner => banner.type === 'SPECIAL_MENU');
      if (specialMenus && specialMenus.length > 0) {
        setCurrentMenus(specialMenus);
      }
      const shortCutImgs = banners.filter(banner => banner.type === 'SHORTCUT');
      if (shortCutImgs && shortCutImgs.length > 0) {
        setShortCuts(shortCutImgs);
      }
      const onPageLoad = banners.find(banner => banner.type === 'ON_PAGE_LOAD');
      if(onPageLoad && onPageLoad.image){
        showModal(
          <ProductModal title="" image={STORAGE_PATH + "/" + onPageLoad.image}/>,
          { centered: true }
        );
      }
    }
  }, [banners]);

  const handleMenuClick = () => {
    history.push("/menu");
  };

  const handleOfferClick = (product) => {
    if (product && product.open_type === "POPUP") {
      showModal(
        <ProductModal
          title="Offers"
          image={STORAGE_PATH + "/" + product.image}
        />,
        { centered: true }
      );
    } else if (product && product.open_type === "URL") {
      window.open(product.url);
    }
  };

  const handleMenuOnClick = (product) => {
    if(product && product.open_type === 'POPUP'){
      showModal(
        <ProductModal title="Special Menu Cards" image={STORAGE_PATH + "/" + product.image}/>,
        { centered: true }
      );
    }else if(product && product.open_type === 'URL'){
      window.open(product.url);
    }
  };

  const handleShortCutOnClick = (product) => {
    if(product && product.open_type === 'POPUP'){
      showModal(
        <ProductModal title="Our Special" image={STORAGE_PATH + "/" + product.image}/>,
        { centered: true }
      );
    }else if(product && product.open_type === 'URL'){
      window.open(product.url);
    }
  };

  const renderPromos = () => {
    if (currentPromos.length > 0) {
      return (
        <section className="section pt-5 pb-5 bg-white homepage-add-section">
          <SectionHeading
            heading="Current Promotions"
            subHeading="Special Waters Edge Seasonal Promotions"
          />
          <Container>
            <Row>
              {currentPromos.map((product, index) => {
                return (
                  <Col md={3} xs={6} key={index}>
                    <ProductBox
                      id={product.id}
                      onClick={() => handleOfferClick(product)}
                      image={STORAGE_PATH + "/" + product.image}
                      imageClass="img-fluid rounded-lg shadow"
                      imageAlt="product"
                      linkUrl="#"
                    />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>
      );
    }

    return null;
  };

  return (
    <>
      <TopSearch />

      <section className="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center gradient">
        <Container>
          <Row>
            <Col xs={12}>
              <span>
                <h2 className="text-white">FRESH . HEALTHY . DELICIOUS</h2>
              </span>
              <Button
                onClick={handleMenuClick}
                className=""
                variant="outline-primary2"
              >
                Click to Order
              </Button>
            </Col>
            {/* <span>Good food and great vibes</span> */}
          </Row>
        </Container>
      </section>

      {renderPromos()}

      <section className="section pt-5 pb-5 homepage-add-section">
        <Container>
          <SectionHeading
            heading="Current Deals"
            subHeading=""
          />
          <Row>
            {currentMenus.map((menu, index) => {
              return (
                <Col md={6} xs={12} key={index}>
                  <div className="pb-2">
                    <ProductBox
                      id={menu.id}
                      onClick={() => handleMenuOnClick(menu)}
                      image={STORAGE_PATH + "/" + menu.image}
                      imageClass="img-fluid rounded-lg shadow"
                      imageAlt="product"
                      linkUrl="#"
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
      <section className="section pt-5 pb-5 homepage-add-section bg-white">
        <Container>
          <Row>
            {shortcuts.map((shortcut, index) => {
              return (
                <Col md={6} xs={12} key={index}>
                  <div className="pb-2">
                    <ProductBox
                      id={shortcut.id}
                      onClick={() => handleShortCutOnClick(shortcut)}
                      image={STORAGE_PATH + "/" + shortcut.image}
                      imageClass="img-fluid rounded-lg shadow"
                      imageAlt="product"
                      linkUrl="#"
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
};

const options = {
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
    1200: {
      items: 4,
    },
  },

  lazyLoad: true,
  pagination: false.toString(),
  loop: true,
  dots: false,
  autoPlay: 2000,
  nav: true,
  navText: [
    "<i class='fa fa-chevron-left'></i>",
    "<i class='fa fa-chevron-right'></i>",
  ],
};

export default withRouter(Index);
