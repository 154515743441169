import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Badge,
  Button,
  Breadcrumb,
  Alert,
} from "react-bootstrap";
import { useRecoilState } from "recoil";
import { metaService } from "services";
import { termsListState } from "store/meta";

const TermsConditions = () => {
  const [terms, setTerms] = useRecoilState(termsListState);

  async function fetchData() {
    const data = await metaService.getTerms();
    setTerms(data);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="section pt-5 pb-5">
      <Container>
        <Row>
          <Col md={8} className="mx-auto bg-white p-5 rounded">
            <h2>Terms and Conditions</h2>
            <hr />
            <h5>Introduction</h5>
            <p>
              Welcome to WatersEdge Delivery (“Company”, “we”, “our”, “us”)!
              These Terms of Service (“Terms”, “Terms of Service”) govern your
              use of our website located at WatersEdge Delivery (together or
              individually “Service”) operated by WatersEdge. Our Privacy Policy
              also governs your use of our Service and explains how we collect,
              safeguard and disclose information that results from your use of
              our web pages. Your agreement with us includes these Terms and our
              Privacy Policy (“Agreements”). You acknowledge that you have read
              and understood Agreements, and agree to be bound of them. If you
              do not agree with (or cannot comply with) Agreements, then you may
              not use the Service, but please let us know by emailing at
              delivery@watersedge.lk so we can try to find a solution. These
              Terms apply to all visitors, users and others who wish to access
              or use Service.
            </p>
            <hr />
            <h5>Communications</h5>
            <p>
              By using our Service, you agree to subscribe to newsletters,
              marketing or promotional materials and other information we may
              send. However, you may opt out of receiving any, or all, of these
              communications from us by following the unsubscribe link or by
              emailing at delivery@watersedge.lk.
            </p>
            <hr />
            <h5>Contests, Sweepstakes and Promotions</h5>
            <p>
              Any contests, sweepstakes or other promotions (collectively,
              “Promotions”) made available through Service may be governed by
              rules that are separate from these Terms of Service. If you
              participate in any Promotions, please review the applicable rules
              as well as our Privacy Policy. If the rules for a Promotion
              conflict with these Terms of Service, Promotion rules will apply.
            </p>
            <hr />
            <h5>Content</h5>
            <p>
              Our Service allows you to post, link, store, share and otherwise
              make available certain information, text, graphics, videos, or
              other material (“Content”). You are responsible for Content that
              you post or share on or through Service, including its legality,
              reliability, and appropriateness. By posting Content on or through
              Service, You represent and warrant that: (i) Content is yours (you
              own it) and/or you have the right to use it and the right to grant
              us the rights and license as provided in these Terms, and (ii)
              that the posting of your Content on or through Service does not
              violate the privacy rights, publicity rights, copyrights, contract
              rights or any other rights of any person or entity. We reserve the
              right to terminate the account of anyone found to be infringing on
              a copyright. You retain any and all of your rights to any Content
              you submit, post or display on or through Service and you are
              responsible for protecting those rights. We take no responsibility
              and assume no liability for Content you or any third party posts
              on or through our Service. However, by posting Content using
              Service you grant us the right and license to use, modify, delete,
              publicly perform, publicly display, reproduce, and distribute such
              Content on and through Service. You agree that this license
              includes the right for us to make your Content available to other
              users of Service, who may also use your Content subject to these
              Terms. Property.lk has the right but not the obligation to monitor
              and edit all Content provided by users. In addition, Content found
              on or through this Service are the property of Property.lk or used
              with permission. You may not distribute, modify, transmit, reuse,
              download, repost, copy, or use said Content, whether in whole or
              in part, for commercial purposes or for personal gain, without
              express advance written permission from us.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TermsConditions;
