import React from "react";
import Icofont from "react-icofont";
import { ErrorMessage as HookformErrorMessage } from "@hookform/error-message";

const ErrorMessage = ({ errors, name }) => {
  return (
    <HookformErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <p style={{ color: "red" }}>
          {" "}
          <Icofont icon="icofont-exclamation-circle" /> {message}
        </p>
      )}
    />
  );
};

export default ErrorMessage;
