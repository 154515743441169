import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Icofont from "react-icofont";
import { useRecoilState, useSetRecoilState } from "recoil";
import { productNameFilterState, productSortFilterState } from "store/product";

const SearchAndOrder = () => {
  const [searchKey, setSearchKey] = useState("");
  const setProductSortFilter = useSetRecoilState(productSortFilterState);
  const [select, setSelect] = useState("DESC");

  const setProductNameFilter = useSetRecoilState(productNameFilterState);

  const handleSearch = ({ target: { value } }) => {
    setSearchKey(value);
  };

  const handleSelect = ({ target: { value } }) => {
    switch (value) {
      case "DESC": {
        setSelect("DESC");
        setProductSortFilter({
          sort: "DESC",
        });
        break;
      }

      case "PRICE_ASC": {
        setSelect("PRICE_ASC");
        setProductSortFilter({
          sort: "DESC",
          price_sort: "ASC",
        });
        break;
      }

      case "PRICE_DESC": {
        setSelect("PRICE_DESC");
        setProductSortFilter({
          sort: "DESC",
          price_sort: "DESC",
        });
        break;
      }

      default:
        setSelect("DESC");
        setProductSortFilter({ sort: "DESC" });
    }
  };

  useEffect(() => {
    setProductNameFilter(searchKey);
  }, [searchKey]);

  return (
    <div className="filters">
      <div className="filters-body rounded shadow-sm bg-white">
        <div className="filters-card p-4">
          <h6 className="text-left d-flex align-items-center p-0">
            Filtered by
          </h6>
          <div>
            <div className="filters-card-body card-shop-filters pt-0">
              <form className="filters-search mb-3">
                <Form.Group>
                  <Icofont icon="search" />
                  <Form.Control
                    type="text"
                    placeholder="Start typing to search..."
                    value={searchKey}
                    onChange={handleSearch}
                  />
                </Form.Group>
                <Form.Group>
                  {/* <Form.Label>Order</Form.Label> */}
                  <Form.Control
                    as="select"
                    onChange={handleSelect}
                    value={select}
                  >
                    <option value="DESC">Recent Items</option>
                    <option value="PRICE_DESC">Price Highest to Lowest</option>
                    <option value="PRICE_ASC">Price Lowest to Highest</option>
                  </Form.Control>
                </Form.Group>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchAndOrder;
