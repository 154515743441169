import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Form,
  InputGroup,
  FormControl,
  Button,
  Image,
} from "react-bootstrap";
import DeliveryAreas from "./DeliveryAreas";
import FontAwesome from "./FontAwesome";
import { useRecoilValue } from "recoil";
import { queryDeliveryAreasList } from "store/meta";

const areas =
  "Battaramulla, Sri Jayawardenapura, Palawatta, Malabe, Thalawattugoda, Hokandara, Pannipitiya, Kottawa, Mattegoda, Homagama, Maharagama, Nugegoda, Boralsgamuwa, Mirihana, Kohuwala, Nawala, Dehiwala, Mount Lavinia, Rathmalana, Moratuwa, Habarakada, Athurugiriya, Kaduwela, Thalahena, Koswatta, Rukmalgama, Borella (Colombo 8), Maradana (Colombo 10), Dematagoda (Colombo 9), Slave Island (Colombo 2), Narahenpita (Colombo 5), Wellawatta (Colombo 6), Cinnamon Garden (Colombo 7), Bambalapitiya (Colombo 4), Kolpity (Colombo 3), Kirulapona, Gothatuwa, Mulleriyawa, Kotikawatta, Angoda, Kalaniya, Kolonnawa, Wellampitiya, Oruwala, Pettah, Rajagiriya, Fort (Colombo 1), Pita Kotte, Piliyandala, Polgasowita";
const Footer = () => {
  return (
    <>
      <section className="section pt-5 pb-5 text-center bg-white">
        <Container>
          <Row>
            <Col sm={12}>
              <h5 className="m-0">
              Order Hotline { " "}
                <Link to="login">+94 70 600 3344</Link>
              </h5>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="footer-bottom-search pt-5 pb-5">
        <div className="container">
          <Suspense fallback="Loading...">
            <DeliveryAreas />
          </Suspense>
        </div>
      </section>
      <footer className="pt-4 pb-4 text-center bg-white">
        <Container>
          <p>
            <Link to="/terms-conditions">Terms & Conditions</Link>&#160;|&#160;
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
          <p className="mt-0 mb-0">
            © 2023 <a href="http://www.vision8.io/">VISION8</a>. All Rights
            Reserved.
          </p>
        </Container>
      </footer>
    </>
  );
};

Footer.propTypes = {
  sectionclassName: PropTypes.string,
  popularCHclassName: PropTypes.string,
  popularCountries: PropTypes.array,
  popularFHclassName: PropTypes.string,
  popularFood: PropTypes.array,
  copyrightText: PropTypes.string,
  madewithIconclassName: PropTypes.string,
  firstLinkText: PropTypes.string,
  firstLink: PropTypes.string,
  secondLinkText: PropTypes.string,
  secondLink: PropTypes.string,
};

Footer.defaultProps = {
  sectionclassName: "footer-bottom-search pt-5 pb-5 bg-white",
  popularCHclassName: "text-black",
  popularCountries: [],
  popularFHclassName: "mt-4 text-black",
  popularFood: [],
  copyrightText: "© 2021-2022 VISION8. All Rights Reserved",
  madewithIconclassName: "heart heart-icon text-danger",
  firstLinkText: "Gurdeep Osahan",
  firstLink: "//www.instagram.com/iamgurdeeposahan/",
  secondLinkText: "Askbootstrap",
  secondLink: "//askbootstrap.com/",
};

export default Footer;
