import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useRecoilState } from "recoil";
import { orderService } from "services";
import { orderState } from "store/order";
import Loading from "../common/Loading";
import OrderCard from "../common/OrderCard";
import NoDataMessage from "components/common/NoDataMessage";

const Orders = () => {
  const ORDER_LIMIT_PER_PAGE = 100;
  //
  const [orders, setOrders] = useRecoilState(orderState);

  const [ordercount, setOrderCount] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    count: 0,
    limit: ORDER_LIMIT_PER_PAGE,
    skip: 0,
  });
  const hasNextPage = pagination.limit === pagination.count;

  async function fetchOrders() {
    try {
      setLoading(true);
      const data = await orderService.getAllOrders();
      setOrderCount(data);
      if (data) {
        return data;
      } else {
        return [
          [],
          { total: 0, count: 0, limit: ORDER_LIMIT_PER_PAGE, skip: 0 },
        ];
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  // async function loadMoredata() {
  //   const data = await fetchOrders();
  //   setOrders({ ...orders, ...data[0] });
  //   setPagination(data[1]);
  // }

  async function fetchData() {
    const data = await fetchOrders();
    setOrders(data[0]);
    setPagination(data[1]);
  }

  // const [sentryRef] = useInfiniteScroll({
  //   loading,
  //   hasNextPage,
  //   onLoadMore: loadMoredata,
  //   disabled: undefined,
  //   rootMargin: "0px 0px 400px 0px",
  // });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="p-4 bg-white h-100 shadow-sm">
        <h4 className="font-weight-bold mt-0 mb-4">My Orders</h4>
        {/* <Row>
          {loading ? (
            <Col
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 16,
              }}
            >
              <Loading />
            </Col>
          ) : null}
        </Row> */}
        <Row>
          {Object.values(orders).map((order, index) => {
            return (
              <Col sm={12} key={index}>
                <OrderCard
                  image="/img/3.jpg"
                  imageAlt=""
                  orderNumber={order.order_id}
                  orderDate={`${format(
                    new Date(order.order_date),
                    "EEEE LLL d"
                  )}, ${order.time_slot}`}
                  deliveredDate={order.status}
                  paymentStatus={order.payment_status}
                  orderTitle={order.order_id}
                  address={order.location}
                  paymentMethod={order.payment_method}
                  orderProducts={order.items.substring(1)}
                  orderTotal={`Rs. ${order.total}`}
                  helpLink=""
                  detailLink={`/order-details/${order.id}`}
                  order
                />
              </Col>
            );
          })}

          {/* {(loading || hasNextPage) && (
            <Col
              ref={sentryRef}
              sm={12}
              className="text-center load-more"
              style={{
                display: "flex",
                alignItems: "center",
                justify: "center",
              }}
            >
              <Loading />
            </Col>
          )} */}
        </Row>
        {ordercount && ordercount.length == 0 ? (
          <Row style={{ height: "100%" }}>
            <Col
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Row>
                <Col
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <NoDataMessage
                    message="No Orders Available."
                    style={{ color: "#000" }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
      </div>
    </>
  );
};
export default Orders;
