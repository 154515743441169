import { PRODUCT } from "assets/img";
import { STORAGE_PATH } from "constant";
import React, { useMemo, useState } from "react";
import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import Icofont from "react-icofont";
import { useRecoilState } from "recoil";
import { cartState } from "store/product";
import { toast } from "react-toastify";

const ProductModal = ({ product, onHide }) => {
  const MAX_QUANTITY = 99;
  const MIN_QUANTITY = 1;

  const id = product.id;
  const options = product.options;
  const potions = product.potion_sizes;
  const image =
    product.images.length > 0
      ? `${STORAGE_PATH}/${product.images[0].url}`
      : PRODUCT;
  //
  const [option, setOption] = useState(product.default_option.id);
  const [potion, setPotion] = useState(product.default_portion.id);

  const price = useMemo(() => {
    const optionPrice = product.options[option].price;
    const potionPrice = product.potion_sizes[potion].price;
    return optionPrice + potionPrice;
  }, [option, potion]);
  const optionName = options[option].name;
  const potionName = potions[potion].name;
  const [quantity, setQuantity] = useState(1);
  const total = useMemo(() => {
    return price * quantity;
  }, [price, quantity]);
  const [cart, setCart] = useRecoilState(cartState);

  const handleAdd = () => {
    let value = quantity;
    if (value < MAX_QUANTITY) {
      ++value;
      setQuantity(value);
    }

    // show alert
  };

  const handleRemove = () => {
    let value = quantity;
    if (value > MIN_QUANTITY) {
      --value;
      setQuantity(value);
    }
    // show alert
  };

  const handleOption = ({ target: { value } }) => {
    setOption(parseInt(value));
  };

  const handlePotion = ({ target: { value } }) => {
    setPotion(parseInt(value));
  };

  const handleAddToCart = () => {
    setCart({
      ...cart,
      [`${id}.${option}.${potion}`]: {
        id,
        name: product.name,
        quantity,
        optionId: option,
        optionName,
        potionId: potion,
        potionName,
        total,
        price,
      },
    });
    const toastId = toast("Updating Address", { autoClose: false });
    toast.update(toastId,{
      render: "Item added to the cart!",
      autoClose: 2500,
    });

    onHide();
  };

  return (
    <React.Fragment>
      <Modal.Header closeButton={true}>
        <Modal.Title
          as="h5"
          id="add-address"
          style={{ textTransform: "capitalize" }}
        >
          {product.name}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: 0 }}>
        <img
          className="d-block w-100"
          // style={{ maxHeight: 200, height: 200 }}
          src={image}
          alt="product"
        />
        <div style={{ padding: 16 }}>
          <div className="pt-2">
            <h6>Description</h6>
            <p>{product.description}</p>
          </div>
          <Form>
            <div className="form-row">
              <Form.Group className="col-xs-12 col-md-12">
                <Form.Label>Option</Form.Label>
                <Form.Control
                  as="select"
                  size="lg"
                  onChange={handleOption}
                  readOnly={Object.keys(options).length === 1}
                >
                  {Object.values(options).map((option, index) => (
                    <option key={index} value={option.id}>
                      {`${option.name} (Rs. ${option.price})`}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="col-xs-12 col-md-12">
                <Form.Label>Potion Size</Form.Label>
                <Form.Control
                  as="select"
                  size="lg"
                  onChange={handlePotion}
                  readOnly={Object.keys(potions).length === 1}
                >
                  {Object.values(potions).map((potion, index) => (
                    <option key={index} value={potion.id}>
                      {potion.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {/* <Form.Group className="col-xs-12 col-md-12">
                <Form.Label>Message to Chef</Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Type here.."
                  rows="2"
                  size="lg"
                />
              </Form.Group> */}
              <FormGroup style={{ width: "100%" }}>
                <Form.Label style={{ paddingLeft: 5 }}>Quantity</Form.Label>
                <div
                  className="count-number float-right"
                  style={{ paddingRight: 5 }}
                >
                  <Button
                    variant="outline-secondary"
                    onClick={handleRemove}
                    className="btn-lg left dec"
                  >
                    {" "}
                    <Icofont icon="minus" />{" "}
                  </Button>
                  <input
                    className="count-number-input"
                    type="text"
                    value={quantity}
                    readOnly
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={handleAdd}
                    className="btn-lg right inc"
                  >
                    {" "}
                    <Icofont icon="icofont-plus" />{" "}
                  </Button>
                </div>
              </FormGroup>
              <Form.Group
                className="col-xs-12 col-md-12"
                style={{ paddingTop: 32 }}
              >
                <Form.Label style={{ fontWeight: 700 }}>Sub Total</Form.Label>
                <span
                  className="float-right"
                  style={{ fontWeight: 700 }}
                >{`Rs. ${total}`}</span>
              </Form.Group>
            </div>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          onClick={onHide}
          variant="outline-primary"
          className="d-flex w-45 text-center justify-content-center"
        >
          CANCEL
        </Button>
        <Button
          type="button"
          variant="primary"
          className="d-flex w-45 text-center justify-content-center"
          onClick={handleAddToCart}
        >
          ADD TO CART
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
};
export default ProductModal;
