import React from "react";
import { Link } from "react-router-dom";
import { Image, Card, Media, Button } from "react-bootstrap";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import { MASTERCARD, VISACARD, AMEX } from "assets/img";

const getCardImage = (type) => {
  switch (type) {
    case "VISA":
      return VISACARD;
    case "MASTERCARD":
      return MASTERCARD;
    case "AMEX":
        return AMEX;
    default:
      return null;
  }
};

const PaymentCard = (props) => {
  const renderDelete = () => {
    return props.onDelete ? (
      <Link className="text-danger" to="#" onClick={props.onDelete}>
        <Icofont icon="ui-delete" /> DELETE
      </Link>
    ) : null;
  };

  const renderSelect = () => {
    return props.onSelect ? (
      <Button size="sm" variant="outline-primary" onClick={props.onSelect}>
        USE CARD{" "}
      </Button>
    ) : null;
  };

  return (
    <Card
      className={
        props.selected
          ? `payments-item mb-4 shadow-sm border border-primary bg-selected`
          : "bg-white payments-item mb-4 shadow-sm"
      }
    >
      <div className="gold-members p-4">
        <Media>
          <Image
            src={getCardImage(props.type)}
            alt={props.imageAlt}
            className={props.imageclassName}
          />
          <Media.Body>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h6 className="mb-1">{props.title}</h6>
              {props.expired ? <h6 className="mb-1" style={{ color: 'red' }}>EXPIRED</h6> : (props.offer && props.offer !== "" ? <h6 className="mb-1">{props.offer}% OFF</h6> : <div></div>)}
            </div>
            {props.subTitle ? <p>{props.subTitle}</p> : ""}
            <p className="mb-0 text-black font-weight-bold">
              {renderDelete()}
              {renderSelect()}
            </p>
          </Media.Body>
        </Media>
      </div>
    </Card>
  );
};

PaymentCard.propTypes = {
  title: PropTypes.string.isRequired,
  logoImage: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  imageAlt: PropTypes.string,
  imageclassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  offer: PropTypes.string,
  expired: PropTypes.bool
};
PaymentCard.defaultProps = {
  subTitle: "",
  imageAlt: "",
  imageclassName: "",
};

export default PaymentCard;
