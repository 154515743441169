import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageTitle from "./common/PageTitle";
import CouponCard from "./common/CouponCard";
import BankOffers from "./common/BankOffers";
import { offerService } from "services";
import { useRecoilState } from "recoil";
import { cardOffersState, promocodesState } from "store/offer";
import { STORAGE_PATH } from "constant";
import NoDataMessage from "./common/NoDataMessage";

const Offers = () => {
  const [cardfOffers, setCardOffers] = useRecoilState(cardOffersState);
  const [promocodes, setPromocodes] = useRecoilState(promocodesState);

  async function fetchData() {
    Promise.all([
      offerService.getCardOffers(),
      offerService.getPromocodes(),
    ]).then((data) => {
      setCardOffers(data[0]);
      setPromocodes(data[1]);
    });
  }

  const renderOffers = () => {
    if (cardfOffers.length > 0) {
      return cardfOffers.map((offer, index) => (
        <Col key={index} md={6}>
          <BankOffers
            title={offer.title}
            maxCap={offer.maxCap}
            logoImage={`${STORAGE_PATH}/${offer.image}`}
            imageclassName="card-img"
            subTitle={offer.description}
          />
        </Col>
      ));
    }

    return (
      <Col>
        <NoDataMessage message="No offers available at the moment. Please check back later." />
      </Col>
    );
  };

  const renderPromocodes = () => {
    if (promocodes.length > 0) {
      return promocodes.map((promo, index) => (
        <Col key={index} md={4}>
          <CouponCard
            title={promo.title}
            logoImage={`${STORAGE_PATH}/${promo.image}`}
            subTitle={promo.description}
            copyBtnText="COPY CODE"
            couponCode={promo.code}
            moreLinkText=""
          />
        </Col>
      ));
    }

    return (
      <Col>
        <NoDataMessage message="No promocodes available at the moment. Please check back later." />
      </Col>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PageTitle
        title="SPECIAL OFFERS FOR YOU"
        subTitle="Explore top deals and offers exclusively for you!"
      />
      <section className="section pt-5 pb-5 ">
        <Container>
          <Row className="pt-1">
            <Col xs={12}>
              <h4 className="font-weight-bold mt-0 mb-3">
                Exclusive Card Offers
              </h4>
            </Col>
            {renderOffers()}
          </Row>
          <Row>
            <Col md={12}>
              <h4 className="font-weight-bold mt-5 mb-3">Available Coupons</h4>
            </Col>
            {renderPromocodes()}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Offers;
