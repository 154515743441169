import NoDataMessage from "components/common/NoDataMessage";
import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userService } from "services";
import { userPromocodesState } from "store/user";
import CouponCard from "../common/CouponCard";

const Offers = ({ history }) => {
  const [promocodes, setPromocodes] = useRecoilState(userPromocodesState);

  async function fetchData() {
    const data = await userService.getPromocodes();
    if (data) {
      setPromocodes(data);
    }
  }

  const handleRedirect = () => {
    history.push("/");
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="p-4 bg-white h-100 shadow-sm">
        <Row>
          <Col md={12}>
            <h4 className="font-weight-bold mt-0 mb-3">Offers</h4>
          </Col>
          {promocodes.map((promo, index) => (
            <Col key={index} md={6}>
              <CouponCard
                title={promo.title}
                logoImage="img/bank/2.png"
                subTitle={promo.description}
                copyBtnText="COPY CODE"
                couponCode={promo.code}
                noBorder={false}
                moreLinkText=""
              />
            </Col>
          ))}
        </Row>
        {promocodes && promocodes.length === 0 ? (
          <Row style={{ height: "100%" }}>
            <Col
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Row>
                <Col
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <NoDataMessage
                    message="No offers available."
                    style={{ color: "#000" }}
                  />
                </Col>
                {/* <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Button onClick={handleRedirect}>GO BACK TO HOME PAGE</Button>
                </Col> */}
              </Row>
            </Col>
          </Row>
        ) : null}
      </div>
    </>
  );
};
export default withRouter(Offers);
