import ErrorMessage from "components/common/ErrorMessage";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Form,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userService } from "services";
import { deliveryAreasListState } from "store/meta";
import { locationsState } from "store/user";
import { useModalStore } from "store";
import GoogleMap from "./GoogleMap";

const mapErrorStyles = { border: "1px solid red" };

const AddAddressModal = (
  props = {
    defaultValues: {},
  }
) => {
  const defaultValues = props.defaultValues;

  const initialCenter = props.defaultValues.coords.lat
    ? props.defaultValues.coords
    : {
        lat: 6.927079,
        lng: 79.861244,
      };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues,
  });
  const hideModal = useModalStore((state) => state.hideModal);
  const [locations, setLocations] = useRecoilState(locationsState);
  const [coords, setCoords] = useState({
    lat: defaultValues.coords.lat,
    lng: defaultValues.coords.lng,
  });

  const deliveryAreas = useRecoilValue(deliveryAreasListState);

  const onSubmit = async (values) => {
    let findDel;
    if(values.deliveryArea){
      findDel = deliveryAreas.find((rec)=> rec.id == values.deliveryArea)
      if(!findDel)
        return;
    }
    else{
      return;
    }
    const data = {
      city: findDel ? findDel.name : '',
      longitude: "",
      latitude: "",
      tag: values.tag,
      delivery_area: values.deliveryArea,
      address_line_1: values.addressLine1,
      address_line_2: values.addressLine2,
      landmark: values.landmark,
    };

    let location = null;

    if (defaultValues.id) {
      // edit loation
      location = await userService.updateLocation(defaultValues.id, data);
    } else {
      // create location
      location = await userService.createLocation(data);
    }

    setLocations({ ...locations, [location.id]: location });
    hideModal();
  };

  const handleLocationChange = (coords) => {
    setCoords(coords);
  };

  useEffect(() => {
    setValue("coords", coords.lat ? coords : "");
  }, [coords]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton={true}>
          <Modal.Title as="h5" id="add-address">
            {props.title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-row">
            <Form.Group className="col-md-12">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address Line 1"
                isInvalid={errors.addressLine1}
                {...register("addressLine1", {
                  required: "Address Line 1 is required",
                })}
              />
              <ErrorMessage errors={errors} name="addressLine1" />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address Line 2"
                isInvalid={errors.addressLine2}
                {...register("addressLine2")}
              />
              <ErrorMessage errors={errors} name="addressLine2" />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>Delivery Area</Form.Label>

              <Controller
                name="deliveryArea"
                control={control}
                defaultValue={""}
                rules={{ required: "Delivery area is required" }}
                render={({ field }) => (
                  <Form.Control
                    as="select"
                    placeholder="Delivery Area"
                    isInvalid={errors.deliveryArea}
                    {...field}
                  >
                    <option value={""}>Select Delivery Area</option>
                    {deliveryAreas.map((area, index) => (
                      <option key={index} value={area.id}>
                        {area.name}
                      </option>
                    ))}
                  </Form.Control>
                )}
              />

              <ErrorMessage errors={errors} name="deliveryArea" />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>Landmark</Form.Label>
              <Form.Control
                type="text"
                placeholder="Landmark. eg. Near the church"
                isInvalid={errors.landmark}
                {...register("landmark")}
              />
              <ErrorMessage errors={errors} name="landmark" />
            </Form.Group>

            {/* <Form.Group
              className="col-md-12"
              style={errors.coords ? mapErrorStyles : {}}
            >
              <Form.Label>Location</Form.Label>
              <Form.Text style={{ minHeight: 30 }}>
                ** Select a place on the map and Drag & Drop the pin to the
                delivery location
              </Form.Text>
              <Controller
                name="coords"
                control={control}
                rules={{ required: "Please select delivery location" }}
                defaultValue={""}
                render={({ field }) => {
                  return <input type="hidden" {...field} />;
                }}
              />

              <div style={{ minHeight: 300, paddingLeft: 4, paddingRight: 4 }}>
                <GoogleMap
                  initialCenter={initialCenter}
                  location={coords}
                  onChange={handleLocationChange}
                />
              </div>

              <ErrorMessage errors={errors} name="coords" />
            </Form.Group> */}

            <Form.Group className="mb-0 col-md-12">
              <Form.Label>Nickname</Form.Label>
              <ButtonToolbar>
                <Controller
                  control={control}
                  name="tag"
                  rules={{ required: "Nickname is required" }}
                  defaultValue="Other"
                  isInvalid={errors.tag}
                  render={({ field }) => {
                    return (
                      <ToggleButtonGroup
                        className="d-flex w-100"
                        defaultValue="Other"
                        type="radio"
                        {...field}
                      >
                        <ToggleButton variant="info" value={"Home"}>
                          Home
                        </ToggleButton>
                        <ToggleButton variant="info" value={"Work"}>
                          Work
                        </ToggleButton>
                        <ToggleButton variant="info" value={"Other"}>
                          Other
                        </ToggleButton>
                      </ToggleButtonGroup>
                    );
                  }}
                />
              </ButtonToolbar>
              <ErrorMessage errors={errors} name="tag" />
            </Form.Group>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            onClick={props.onHide}
            variant="outline-primary"
            className="d-flex w-45 text-center justify-content-center"
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="d-flex w-45 text-center justify-content-center"
          >
            {props.btnName}
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};
export default AddAddressModal;
