import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmModal = ({ title, message, onHide, onDelete }) => {
  return (
    <React.Fragment>
      <Modal.Header closeButton={true}>
        <Modal.Title as="h5" id="delete-address">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="mb-0 text-black">{message}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          onClick={onHide}
          variant="outline-primary"
          className="d-flex w-45 text-center justify-content-center"
        >
          CANCEL
        </Button>
        <Button
          type="button"
          variant="primary"
          className="d-flex w-45 text-center justify-content-center"
          onClick={onDelete}
        >
          DELETE
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
};
export default ConfirmModal;
