import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel3";
import MayLikeItem from "./MayLikeItem";
import ProductDetailModal from "components/modals/ProductDetailModal";
import { popularProductsState, productsState } from "store/product";
import { useRecoilState, useRecoilValue } from "recoil";
import { productService } from "services";
import { STORAGE_PATH } from "constant";
import { PRODUCT } from "assets/img";
import { useModalStore } from "store";

const ItemsCarousel = () => {
  const [popularProducts, setPopularProducts] =
    useRecoilState(popularProductsState);

  const items = Object.values(popularProducts);

  const showModal = useModalStore((state) => state.showModal);

  const handleAdd = (id) => {
    const product = popularProducts[id];
    showModal(<ProductDetailModal product={product} />);
  };

  async function fetchData() {
    const data = await productService.getPopularProducts();
    if (data) {
      setPopularProducts(data);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      {items.length > 0 && (
        <OwlCarousel
          {...options}
          className="owl-theme owl-carousel-five offers-interested-carousel"
        >
          {items.map((product, index) => {
            const price =
              product.default_option.price + product.default_portion.price;
            const image =
              product.images.length > 0
                ? `${STORAGE_PATH}/${product.images[0].url}`
                : PRODUCT;
            return (
              <div className="item" key={index}>
                <MayLikeItem
                  id={product.id}
                  title={product.name}
                  price={`Rs. ${price}`}
                  image={image}
                  imageClass="img-fluid"
                  imageAlt="carousel"
                  onAdd={handleAdd}
                />
              </div>
            );
          })}
        </OwlCarousel>
      )}
    </React.Fragment>
  );
};

const options = {
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
  lazyLoad: false,
  pagination: false,
  autoPlay: true,
  loop: true,
  dots: true,
  autoplaySpeed: 1000,
  autoplayTimeout: 2000,
  autoplayHoverPause: true,
  nav: false,
  navText: [
    "<i class='icofont-thin-left'></i>",
    "<i class='icofont-thin-right'></i>",
  ],
};

export default ItemsCarousel;
