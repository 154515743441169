import React from "react";
import { Link } from "react-router-dom";
import { Card, Media, Button } from "react-bootstrap";
import Icofont from "react-icofont";
import PropTypes from "prop-types";

const ChooseAddressCard = (props) => {
  const selectedClassName = props.selected
    ? "border border-primary bg-selected"
    : "";

  const handleClick = () => {
    props.onDeliverHere(props.id);
  };

  return (
    <Card
      className={
        "bg-white addresses-item mb-4 " + props.boxClass + selectedClassName
      }
    >
      <div className="gold-members p-4">
        <Media>
          <div className="mr-3">
            <Icofont icon={props.icoIcon} className={props.iconclassName} />
          </div>
          <div className="media-body">
            <h6 className="mb-1 text-secondary">{props.title}</h6>
            <p
              className="text-black"
              style={{
                marginBottom: 0,
                textTransform: "capitalize",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: 220,
                overflow: "hidden",
              }}
            >
              {props.address}
            </p>
            <p
              className="text-black"
              style={{
                marginBottom: 0,
                textTransform: "capitalize",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: 220,
                overflow: "hidden",
              }}
            >
              {props.city}
            </p>
            <p className="text-secondary">Delivery Fee: Rs. {props.price}</p>
            <p className="mb-0 text-black font-weight-bold">
              {props.type === "hasAddress" ? (
                <>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={handleClick}
                  >
                    {" "}
                    DELIVER HERE{" "}
                  </Button>
                  {/* <span>Rs. 500</span> */}
                </>
              ) : (
                <Link
                  className="btn btn-sm btn-primary mr-2"
                  to="#"
                  onClick={props.onAddNewClick}
                >
                  {" "}
                  ADD NEW ADDRESS{" "}
                </Link>
              )}
            </p>
          </div>
        </Media>
      </div>
    </Card>
  );
};

ChooseAddressCard.propTypes = {
  title: PropTypes.string.isRequired,
  icoIcon: PropTypes.string.isRequired,
  iconclassName: PropTypes.string,
  address: PropTypes.string,
  price: PropTypes.string || PropTypes.number,
  onDeliverHere: PropTypes.func,
  onAddNewClick: PropTypes.func,
  selected: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

ChooseAddressCard.defaultProps = {
  type: "hasAddress",
  price: "",
  selected: false,
};

export default ChooseAddressCard;
