import React from "react";

const NoDataMessage = ({ message, style = {} }) => {
  return (
    <h5 style={{ color: "#7f7f7f", ...style }}>
      <i class="icofont-exclamation-circle" /> {message}
    </h5>
  );
};

export default NoDataMessage;
