import React from "react";
import OwlCarousel from "react-owl-carousel3";
import ProductBox from "../home/ProductBox";
import { useRecoilValue } from "recoil";
import { categoriesListState } from "store/category";
import { STORAGE_PATH } from "constant";

const CategoriesCarousel = () => {
  const categories = useRecoilValue(categoriesListState);

  return (
    <React.Fragment>
      {categories.length > 0 && (
        <OwlCarousel
          nav={false}
          loop={false}
          {...options}
          className="owl-carousel-category owl-theme"
        >
          {categories.map((category) => (
            <div className="item  text-center" key={category.id}>
              <ProductBox
                boxClass="osahan-category-item"
                title={category.name}
                // counting="20"
                image={`${STORAGE_PATH}/${category.image}`}
                imageClass="img-fluid"
                imageAlt="carousel"
                linkUrl={`/menu?cuisine=${category.id}`}
              />
            </div>
          ))}
        </OwlCarousel>
      )}
    </React.Fragment>
  );
};

const options = {
  responsive: {
    0: {
      items: 3,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 6,
    },
    1200: {
      items: 8,
    },
  },
  loop: true,
  lazyLoad: true,
  autoplay: true,
  dots: true,
  autoplaySpeed: 1000,
  autoplayTimeout: 2000,
  autoplayHoverPause: true,
  nav: false,
  // navText: [
  //   "<i class='fa fa-chevron-left'></i>",
  //   "<i class='fa fa-chevron-right'></i>",
  // ],
};

export default CategoriesCarousel;
