import { atom } from "recoil";

export const dishListState = atom({
  key: "subCategoriesListState",
  default: [],
});

export default {
  dishListState,
};
