import apiHandler from "./apiHandler";

export const getDeliveryTimeSlots = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("delivery-time-slots/search", data);
    return response.data.data.results.length > 0
      ? response.data.data.results[0]["time_slots"]
      : [];
  } catch (error) {
    return null;
  }
};

// export const deliverytimeslotDetails = async (orderdate, rowsPerPage, page) => {
//   try {
//     let filter = [];
//     const datetype = "T00:00:00.000Z";
//     if (orderdate && orderdate.length > 0) {
//       filter.push({ date: orderdate + datetype });
//     }

//     let response = await axios({
//       method: "post",
//       baseURL: apiHandler + "/delivery-time-slots/admin/search",
//       data: {
//         // filter: [{ date: "2021-09-02T00:00:00.000Z" }],
//         filter: filter,
//         limit: rowsPerPage,
//         skip: page * 10,
//         sort: "DESC",
//       },

//     });

//     let deliverytimeslotDetail = response.data.data;
//     return deliverytimeslotDetail;
//   } catch (err) {
//     throw err;
//   }
// };

export const createOrder = async (
  data = {
    product_data: [],
    location_id: "",
    payment_method: "",
    order_date: "",
    time_slot: "",
    card_id: null,
  }
) => {
  try {
    const response = await apiHandler.post("orders/create", data);
    return response.data.data;
  } catch (error) {
    return null;
  }
};
export const getAllOrders = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "ASC",
  }
) => {
  try {
    const response = await apiHandler.post("orders/search", data);

    const pagination = response.data.data.pagination;

    const orders = response.data.data.results.reduce((obj, item) => {
      obj[item.id] = {
        ...item,
        items: item.items.reduce((products, item) => {
          const productName = item.product.name;

          return `${products}, ${productName}`;
        }, ""),
      };

      return obj;
    }, {});
    return [orders, pagination];
  } catch (error) {
    return null;
  }
};
export const getOrderById = async (orderId = "") => {
  try {
    const response = await apiHandler.get(`orders/${orderId}`);
    const order = response.data.data;
    return {
      [order.id]: order,
    };
  } catch (error) {
    return null;
  }
};

export default {
  getAllOrders,
  getOrderById,
  createOrder,
  getDeliveryTimeSlots,
};
