import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { deliveryAreasListState } from "store/meta";

const DeliveryAreas = () => {
  const deliveryAreas = useRecoilValue(deliveryAreasListState);

  return (
    <Row>
      <Col xs={12}>
        <p className="text-black">DELIVERY AREAS</p>
        <div className="search-links">
          {deliveryAreas.map((area, i) => {
            return (
              <span key={i}>
                <Link to="#">{area.name}</Link> |
              </span>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default DeliveryAreas;
