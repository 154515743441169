import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import React from "react";

const mapStyles = {
  width: "100%",
  height: "290px",
  marginLeft: "-5px",
};

const MapContainer = (props) => {
  const { location, onChange } = props;

  const handleMarkerDragend = (props, marker, e) => {
    onChange({
      lat: marker.position.lat(),
      lng: marker.position.lng(),
    });
  };

  const handleMapClick = (mapProps, map, clickEvent) => {
    if (location.lat && location.lng) {
      onChange(location);
    } else {
      onChange({
        lat: map.getCenter().lat(),
        lng: map.getCenter().lng(),
      });
    }
  };

  return (
    <Map
      google={props.google}
      zoom={11}
      style={mapStyles}
      initialCenter={props.initialCenter}
      onClick={handleMapClick}
    >
      <Marker
        position={props.location}
        onDragend={handleMarkerDragend}
        name={"Deliverry Location"}
        draggable={true}
      ></Marker>
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBFTE1lTiY-Epmo4wulOrJScbxtzl4w4Jw",
})(MapContainer);
