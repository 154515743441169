import ErrorMessage from "components/common/ErrorMessage";
import { BACKGROUND1, LOGO } from "assets/img";
import qs from "qs-lite";
import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, withRouter } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userService } from "services";
import { userState } from "store/user";
import { toast } from "react-toastify";

const Login = ({ history }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const setUser = useSetRecoilState(userState);

  const query = qs.parse(history.location.search.substring(1));
  const redirectPath = query.to ? query.to : "/";

  const onSubmit = async (values) => {
    setSubmitting(true);
    try {
      const {
        id,
        name,
        email,
        phone,
        token,
        updated_at: updatedAt,
        created_at: createdAt,
      } = await userService.login(values);
      
      console.log({
        id,
        name,
        email,
        phone,
        token,
        updated_at: updatedAt,
        created_at: createdAt,
      })

      if(id && token){
        localStorage.setItem("access-token",token)
        setUser({
          id,
          email,
          name,
          phone,
          token,
          createdAt,
          updatedAt,
        });
        toast.success("You have successfully logged in!");
        history.push(redirectPath);
      }
      setSubmitting(false);
    } catch (error) {
      // setError(error.message);
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container fluid className="bg-white">
      <Row>
        <Col
          md={4}
          lg={6}
          className="d-none d-md-flex"
          style={{
            backgroundImage: `url('${BACKGROUND1}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Col md={8} lg={6}>
          <div className="login d-flex align-items-center py-5">
            <Container>

              <div className="d-flex align-items-center">
                <Link to="/">
                  <img
                    src={LOGO}
                    alt="logo"
                    style={{ width: 150, margin: "auto", paddingBottom: 40 }}
                  />
                </Link>
              </div>

              <Row>
                <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                  <h3 className="login-heading mb-4">Login for Faster Check-out</h3>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Alert
                      variant="danger"
                      show={Boolean(error)}
                      onClose={() => setError(null)}
                      dismissible
                    >
                      {error}
                    </Alert>
                    <div className="form-label-group">
                      <Form.Control
                        type="tel"
                        id="phone"
                        isInvalid={errors.phone}
                        disabled={submitting}
                        autoComplete="off"
                        placeholder="Phone Number"
                        {...register("phone", {
                          required: "Phone number is required",
                          minLength: {
                            value: 10,
                            message: "Phone number must be 10 charactors",
                          },
                          maxLength: {
                            value: 10,
                            message: "Phone number must be 10 charactors",
                          },
                        })}
                      />
                      <Form.Label htmlFor="phone">Phone Number</Form.Label>
                      <ErrorMessage errors={errors} name="phone" />
                    </div>
                    <div className="form-label-group">
                      <Form.Control
                        autoComplete="off"
                        type="password"
                        id="password"
                        placeholder="Password"
                        isInvalid={errors.password}
                        disabled={submitting}
                        {...register("password", {
                          required: "Password is required",
                        })}
                      />
                      <Form.Label htmlFor="password">Password</Form.Label>
                      <ErrorMessage errors={errors} name="password" />
                    </div>
                    <Button
                      type="submit"
                      className="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2"
                      disabled={submitting}
                    >
                      Sign in
                    </Button>
                    <div className="text-center pt-3">

                      <Link className="font-weight-bold" to="/resetpassword">
                        Forgot Password
                      </Link>
                    </div>
                    <div className="text-center pt-3">
                      Don’t have an account?{" "}
                      <Link className="font-weight-bold" to="/register">
                        Sign Up
                      </Link>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Login);
