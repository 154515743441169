import qs from "qs-lite";
import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { subCategoriesListState } from "store/subCategory";

const SubCategories = ({ history }) => {
  const subCategories = useRecoilValue(subCategoriesListState);

  const subCategoryIdFromQuery = useMemo(() => {
    const { outlet: subCategoryId = "" } = qs.parse(
      history.location.search.substring(1)
    );

    return subCategoryId;
  }, [history.location]);

  const handleChange = ({ target: { value } }) => {
    const query = qs.parse(history.location.search.substring(1));
    const updatedQuery = qs.stringify({ ...query, outlet: value });
    history.push({ pathname: "/menu", search: updatedQuery });
  };

  return (
    <div className="filters pt-2">
      <div className="filters-body rounded shadow-sm bg-white">
        <div className="filters-card p-4">
          <h6 className="text-left d-flex align-items-center p-0">
            Filter by Outlet
          </h6>
          <div>
            <div className="filters-card-body card-shop-filters pt-0">
              <Form>
                <Form.Check
                  custom
                  type="radio"
                  name="subCategory"
                  id="defaultSubCategory"
                  label="Any"
                  defaultChecked={!subCategoryIdFromQuery}
                  value={""}
                  onChange={handleChange}
                />
                {subCategories.map((subcat, index) => (
                  <Form.Check
                    key={index}
                    custom
                    type="radio"
                    name="subCategory"
                    defaultChecked={subCategoryIdFromQuery == subcat.id}
                    id={subcat.name}
                    label={subcat.name}
                    value={subcat.id}
                    onChange={handleChange}
                  />
                ))}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SubCategories);
