import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { userService } from "../../services";
import { userCardsState } from "../../store/user";
import PaymentCard from "../common/PaymentCard";
import NoDataMessage from "components/common/NoDataMessage";
import ConfirmCardModal from "../modals/ConfirmCardModal";
import { useModalStore } from "store";

const Payments = () => {
  const [userCards, setUserCards] = useRecoilState(userCardsState);
  const showModal = useModalStore((state) => state.showModal);

  async function fetchData() {
    const userCards = await userService.getUserCards();
    if (userCards) {
      setUserCards(userCards);
    }
  }

  async function createCardConf() {
    showModal(
      <ConfirmCardModal
        title="Confirm & Proceed"
        message={`By confirming this, you are giving consent to save your card details and authorize a charge of Rs. 100.00 as a transaction validation fee. Upon confirmation, you will receive a one-time CashBack coupon worth Rs. 100.00.`}
        onConfirm={createCard}
      />
    );
  }

  async function createCard() {
    const cardRequestDetails = await userService.addCard();

    window.location = cardRequestDetails.paymentPageUrl;
  }

  async function deleteCard(cardId) {
    await userService.deleteUserCard(cardId);
    fetchData();
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-4 bg-white h-100 shadow-sm">
      <Row className="mb-2">
        <Col md={12}>
          <h4 className="font-weight-bold mt-0 mb-3 float-left"> Cards</h4>
          <div className="float-right">
            <Button onClick={createCardConf} size="sm">
              Add Credit/Debit Cards
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        {userCards.map((card, index) => {
          return (
            <Col md={6} key={index}>
              <PaymentCard
                type={card.type}
                title={card.cardNumber}
                logoImage="img/bank/1.png"
                imageclassName="mr-3"
                subTitle={`VALID TILL ${card.validity}`}
                onDelete={() => deleteCard(card.id)}
              />
            </Col>
          );
        })}
      </Row>
      {userCards && userCards.length === 0 ? (
        <Row style={{ height: "100%" }}>
          <Col
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row>
              <Col
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <NoDataMessage
                  message="No  Cards Available."
                  style={{ color: "#000" }}
                />
              </Col>
              {/* <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Button onClick={handleRedirect}>GO BACK TO HOME PAGE</Button>
                </Col> */}
            </Row>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
export default Payments;
