import React from "react";
import { Modal } from "react-bootstrap";

const ProductModal = ({ title, image }) => {
  return (
    <React.Fragment>
        <Modal.Header closeButton={true}>
          <Modal.Title as="h5" id="add-address">
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body size="lg">
          <img className="d-block w-100" src={image} alt="Second slide" />
        </Modal.Body>
    </React.Fragment>
  );
};
export default ProductModal;
