import { WHATSAPP_IMG } from "assets/img";
import React, { Suspense } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Categories from "./Categories";
import ItemsCarousel from "./common/ItemsCarousel";
import PageTitle from "./common/PageTitle";
import Products from "./Products";
import SearchAndOrder from "./SearchAndOrder";
import SubCategories from "./SubCategories";
import Dishes from "./Dishes";
import { Link } from "react-router-dom";

const List = () => {
  const RedirectPage = () => {
    React.useEffect(() => {
      window.location.replace('https://wa.me/94763232490')
    }, [])
  }

  return (
    <>
      <PageTitle title="FRESH . HEALTHY . DELICIOUS" />
      <section className="section pt-5 pb-5 products-listing">
        <Container>
          <Row className="d-none-m">
            <Col md={12}></Col>
          </Row>
          <Row>
            <Col md={3}>
              <SearchAndOrder />

              <Suspense fallback="Loading...">
                <Categories />
              </Suspense>

              <Suspense fallback="Loading...">
                <SubCategories />
              </Suspense>
              <Suspense fallback="Loading...">
                <Dishes />
              </Suspense>

              <div className="filters pt-2">
                <div className="filters-body rounded shadow-sm bg-white">
                  <div className="filters-card p-4">
                    <h6 className="text-left d-flex align-items-center p-0">
                      Inquiry or Feedback
                    </h6>
                    <div onClick={() => RedirectPage()}>
                      <div className="filters-card-body card-shop-filters pt-0" onClick={() => RedirectPage()}>
                        <div

                          onClick={() => RedirectPage()}
                        >
                          <img

                            onClick={() => RedirectPage()}
                            src={WHATSAPP_IMG}
                            alt="logo"
                            style={{
                              width: "100%",
                              margin: "auto",
                              paddingBottom: 0,
                              maxWidth: 200,
                            }}
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={9}>
              <h6>Most Popular </h6>
              <ItemsCarousel />
              <div style={{ marginTop: 20 }} />
              <Products />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default List;
