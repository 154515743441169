import React from "react";
import { Button } from "react-bootstrap";
import Icofont from "react-icofont";

const CheckoutItem = (props) => {
  const MAX_QUANTITY = 99;
  const MIN_QUANTITY = 1;

  const handleIncrement = () => {
    let value = props.quantity;
    if (value < MAX_QUANTITY) {
      ++value;
      handleUpdateCart(value);
    }

    // show alert
  };

  const handleDecrement = () => {
    let value = props.quantity;
    if (value > MIN_QUANTITY) {
      --value;
      handleUpdateCart(value);
    }
    // show alert
  };

  const handleUpdateCart = (quantity) => {
    const total = props.price * quantity;
    props.onAdd(props.id, props.optionId, props.potionId, quantity, total);
  };

  const handleRemove = () => {
    props.onRemove(props.id, props.optionId, props.potionId);
  };

  return (
    <div className="gold-members p-2 border-bottom">
      <p className="text-gray mb-0 float-right ml-2">
        {props.priceUnit}
        {props.price * props.quantity}
      </p>
      <span className="count-number float-right">
        <Button
          variant="outline-secondary"
          onClick={handleDecrement}
          className="btn-sm left dec"
        >
          {" "}
          <Icofont icon="minus" />{" "}
        </Button>
        <input
          className="count-number-input"
          type="text"
          value={props.quantity}
          readOnly
        />
        <Button
          variant="outline-secondary"
          onClick={handleIncrement}
          className="btn-sm right inc"
        >
          {" "}
          <Icofont icon="icofont-plus" />{" "}
        </Button>
      </span>
      <div className="media" style={{ display: "flex", alignItems: "center" }}>
        <div className="mt-1 mr-2">
          <Icofont
            icon="close"
            style={{ fontSize: 18 }}
            onClick={handleRemove}
          />
        </div>
        <div className="media-body">
          <div className="mb-0 text-black">{props.itemName}</div>
          <div>
            ({props.optionName} {props.potionName})
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutItem;
