import apiHandler from "./apiHandler";

const getCategories = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("categories/search", data);
    return response.data.data.results;
  } catch (error) {
    return null;
  }
};

const getSubCategories = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("sub-categories/search", data);
    return response.data.data.results;
  } catch (error) {
    return null;
  }
};

const getDeliveryAreas = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("delivery-areas/search", data);
    return response.data.results;
  } catch (error) {
    return null;
  }
};

const getSettings = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("settings/search", data);
    return response.data.data.results;
  } catch (error) {
    return null;
  }
};

const getBanners = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("banner-management/search", data);
    return response.data.data.results;
  } catch (error) {
    return null;
  }
};

const getDeleveryAreas = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "ASC",
  }
) => {
  try {
    const response = await apiHandler.post("delivery-areas/search", data);
    return response.data.data.results;
  } catch (error) {
    return null;
  }
};

const getTerms = async (
  data = {
    filter: [],
    limit: 10,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("terms/search", data);
    return response.data.data.results;
  } catch (error) {
    return null;
  }
};
export default {
  getCategories,
  getSubCategories,
  getDeliveryAreas,
  getSettings,
  getBanners,
  getDeleveryAreas,
  getTerms,
};
