import { toast } from "react-toastify";
import apiHandler from "./apiHandler";

// util fn
const reorderProducts = (results) => {
  const productsByOrder = [];
  const products = results.reduce((obj, item) => {
    productsByOrder.push(item.id);
    const options = item.options.reduce((obj, option) => {
      obj[option.id] = {
        ...option,
      };

      return obj;
    }, {});

    const potion_sizes = item.potion_sizes.reduce((obj, portion) => {
      obj[portion.id] = {
        ...portion,
      };

      return obj;
    }, {});

    obj[item.id] = {
      ...item,
      options,
      potion_sizes,
    };

    return obj;
  }, {});

  return [products, productsByOrder];
};
export const getProducts = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("products/search", data);

    const pagination = response.data.data.pagination;

    const [products, productsByOrder] = reorderProducts(
      response.data.data.results
    );

    return [products, productsByOrder, pagination];
  } catch (error) {
    return null;
  }
};
export const getProductById = async (productId = "") => {
  try {
    const response = await apiHandler.get(`products/${productId}`);
    return response.data.data;
  } catch (error) {
    return null;
  }
};
export const getPopularProducts = async (
  data = { filter: [], limit: 5, skip: 0, price_sort: "DESC" }
) => {
  try {
    const response = await apiHandler.post("products/popular", data);
    const [products] = reorderProducts(response.data.data.results);
    return products;
  } catch (error) {
    return null;
  }
};

export const getFavoriteProducts = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("wish-list/search", data);

    const pagination = response.data.data.pagination;

    const products = response.data.data.results.reduce((obj, item) => {
      const options = item.product.options.reduce((obj, option) => {
        obj[option.id] = {
          ...option,
        };

        return obj;
      }, {});

      const potion_sizes = item.product.potion_sizes.reduce((obj, portion) => {
        obj[portion.id] = {
          ...portion,
        };

        return obj;
      }, {});

      obj[item.product.id] = {
        ...item.product,
        favorite_id: item.id,
        options,
        potion_sizes,
      };

      return obj;
    }, {});

    return [products, pagination];
  } catch (error) {
    return null;
  }
};

export const addFavoriteProduct = async (
  data = { product: "" },
  productName
) => {
  let toastId = null;
  try {
    toastId = toast(`Adding ${productName} to Favorites`, {
      autoClose: false,
    });
    const response = await apiHandler.post("wish-list/create", data);
    toast.update(toastId, {
      render: `Adding ${productName} to Favorites - Success!`,
      autoClose: 1500,
    });
    return response.data.data;
  } catch (error) {
    if (error.response && error.response.status == 404) {
      toast.update(toastId, {
        render: `Adding ${productName} to Favorites - Success!`,
        autoClose: 1500,
      });
    } else {
      return null;
    }
  }
};

export const removeFavoriteProduct = async (productId = "", productName) => {
  try {
    const toastId = toast(`Removing ${productName} to Favorites`, {
      autoClose: false,
    });
    await apiHandler.delete(`wish-list/${productId}`);
    toast.update(toastId, {
      render: `Removing ${productName} to Favorites - Success!`,
      autoClose: 1000,
    });
  } catch (error) {
    return null;
  }
};
export default {
  getProducts,
  getProductById,
  getPopularProducts,
  getFavoriteProducts,
  addFavoriteProduct,
  removeFavoriteProduct,
};
