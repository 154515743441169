import apiHandler from "./apiHandler";

export const getCardOffers = async (
  data = {
    filter: [],
    limit: 500,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("card-offers/search", data);

    return response.data.data.results;
  } catch (error) {
    // handle error
  }
};

export const getPromocodes = async (
  data = {
    filter: [],
    limit: 10,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("promocodes/search", data);
    return response.data.data.results;
  } catch (error) {
    // handle error
  }
};
export default {
  getCardOffers,
  getPromocodes,
};
