import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { userService } from "services";
import { useModalStore } from "store";
import { toastState } from "store/ui";
import { locationsState } from "store/user";
import AddressCard from "../common/AddressCard";
import AddAddressModal from "../modals/AddAddressModal";
import ConfirmModal from "../modals/ConfirmModal";
import NoDataMessage from "components/common/NoDataMessage";

const getLocationIcon = (tag = "") => {
  switch (tag) {
    case "Home":
      return "ui-home";
    case "Work":
      return "briefcase";
    default:
      return "location-pin";
  }
};

const Addresses = () => {
  const showModal = useModalStore((state) => state.showModal);
  const hideModal = useModalStore((state) => state.hideModal);
  const [toasts, setToast] = useRecoilState(toastState);
  const [locations, setLocations] = useRecoilState(locationsState);

  const [locationcount, setLocationCount] = useState([]);

  const handleAddressCreateModal = () => {
    showModal(
      <AddAddressModal
        btnName="ADD"
        defaultValues={{ coords: {} }}
        title="Add Delivery Address"
      />
    );
  };

  const handleAddressDeleteModal = (id, address) => {
    const handleAddressDelete = async (id) => {
      await userService.deleteLocation(id);
      const clone = JSON.parse(JSON.stringify(locations));

      delete clone[id];
      setLocations({ ...clone });
      hideModal();
      setToast([
        {
          id: nanoid(),
          title: "Success!",
          message: `Address successfully deleted`,
          show: true,
        },
      ]);
    };
    showModal(
      <ConfirmModal
        onDelete={() => handleAddressDelete(id)}
        title="Delete Address"
        message={`Are you want to delete the address: "${address}" ?`}
      />
    );
  };

  const handleAddressEditModal = (id) => {
    const location = locations[id];

    const defaultValues = {
      id: location.id,
      deliveryArea: location.delivery_area.id + '',
      addressLine1: location.address_line_1,
      addressLine2: location.address_line_2,
      city: location.city,
      tag: location.tag,
      landmark: location.landmark,
      coords: { lat: location.latitude, lng: location.longitude },
    };

    showModal(
      <AddAddressModal
        btnName="UPDATE"
        defaultValues={defaultValues}
        title="Edit Delivery Address"
      />
    );
  };

  async function fetchData() {
    const locations = await userService.getLocations();

    setLocationCount(locations);

    if (locations) {
      setLocations(locations);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (Object.values(locations) && Object.values(locations).length === 0) {
  } else {
  }

  return (
    <div className="p-4 bg-white h-100 shadow-sm">
      <Row className="mb-2">
        <Col md={12}>
          <h4 className="font-weight-bold mt-0 mb-3 float-left">
            Manage Addresses
          </h4>
          <div className="float-right">
            <Button onClick={handleAddressCreateModal} size="sm">
              Add Delivery Address
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        {Object.values(locations).map((location, index) => {
          const address = `${location.address_line_1}, ${location.address_line_2}`;

          return (
            <Col key={index} md={6}>
              <AddressCard
                id={location.id}
                boxClass="shadow"
                title={location.tag}
                city={location.delivery_area.name}
                icoIcon={getLocationIcon(location.tag)}
                iconclassName="icofont-3x"
                address={address}
                onEditClick={() => handleAddressEditModal(location.id)}
                onDeleteClick={() =>
                  handleAddressDeleteModal(location.id, address)
                }
              />
            </Col>
          );
        })}
      </Row>
      {Object.values(locations) && Object.values(locations).length === 0 ? (
        <Row style={{ height: "100%" }}>
          <Col
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row>
              <Col
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <NoDataMessage
                  message="No Addresses Added."
                  style={{ color: "#000" }}
                />
              </Col>
              {/* <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Button onClick={handleRedirect}>VIEW MENU</Button>
                </Col> */}
            </Row>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
export default Addresses;
