import "bootstrap/dist/css/bootstrap.min.css";
import { ModalContainer } from "components/common/ModalContainer";
import "font-awesome/css/font-awesome.min.css";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSetRecoilState } from "recoil";
import { metaService } from "services";
import { categoriesListState } from "store/category";
import { bannersListState, deliveryAreasListState,freeDeliveryAreasListState } from "store/meta";
import { subCategoriesListState } from "store/subCategory";
import Checkout from "./components/Checkout";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import Extra from "./components/Extra";
import Index from "./components/Index";
import Invoice from "./components/Invoice";
import List from "./components/List";
import Login from "./components/Login";
import MyAccount from "./components/MyAccount";
import NotFound from "./components/NotFound";
import Offers from "./components/Offers";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Register from "./components/Register";
import TermsConditions from "./components/TermsConditions";
import Error from "./components/Error";
import Success from "./components/Success";
import TrackOrder from "./components/TrackOrder";
import AuthGuard from "components/common/AuthGuard";

import ResetPassword from "./components/ResetPassword";

//
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-select2-wrapper/css/select2.css";

const App = (props) => {
  const setCategories = useSetRecoilState(categoriesListState);
  const setBanners = useSetRecoilState(bannersListState);
  const setDeliveryAreas = useSetRecoilState(deliveryAreasListState);
  const setFreeDeliveryAreas = useSetRecoilState(freeDeliveryAreasListState);
  const setSubCategories = useSetRecoilState(subCategoriesListState);

  function fetchData() {
    Promise.all([
      metaService.getCategories(),
      metaService.getBanners(),
      metaService.getDeleveryAreas(),
      metaService.getSubCategories(),
    ]).then((data) => {
      if (data[0]) {
        setCategories(data[0]);
      }

      if (data[1]) {
        setBanners(data[1]);
      }

      if (data[2]) {
        var filtered1 = data[2].filter(function(el) { return el.description == "DELIVERY0"; }); 
        setFreeDeliveryAreas(filtered1);
        var filtered2 = data[2].filter(function(el) { return el.description != "DELIVERY0"; }); 
        setDeliveryAreas(filtered2);
      }

      if (data[3]) {
        setSubCategories(data[3]);
      }
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ModalContainer />
      <ToastContainer
        progressStyle={{
          background: "rgb(41,128,185)",
          background:
            "linear-gradient(90deg, rgba(41,128,185,1) 0%, rgba(13,70,116,1) 60%)",
        }}
      />
      {props.location.pathname !== "/login" &&
      props.location.pathname !== "/register" && props.location.pathname !== "/resetpassword"  ? (
        <Header />
      ) : (
        ""
      )}
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path="/offers" exact component={Offers} />
        <Route path="/menu" exact component={List} />
        <Route path="/myaccount" component={AuthGuard.IsLoggedIn(MyAccount)} />
        <Route path="/404" exact component={NotFound} />
        <Route path="/extra" exact component={Extra} />
        <Route path="/terms-conditions" exact component={TermsConditions} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/login" exact component={AuthGuard.IsPublic(Login)} />
        <Route path="/resetpassword" exact component={AuthGuard.IsPublic(ResetPassword)} />
        <Route
          path="/register"
          exact
          component={AuthGuard.IsPublic(Register)}
        />
        <Route
          path="/order-details/:orderId"
          exact
          component={AuthGuard.IsLoggedIn(TrackOrder)}
        />
        {/* <Route path="/invoice" exact component={Invoice} /> */}
        <Route
          path="/checkout"
          exact
          component={AuthGuard.IsLoggedIn(Checkout)}
        />
        <Route path="/success" exact component={AuthGuard.IsLoggedIn(Success)} />
        <Route path="/error" exact component={AuthGuard.IsLoggedIn(Error)} />
        {/* <Route path="/detail" exact component={Detail} /> */}
        <Route exact component={NotFound} />
      </Switch>
      {props.location.pathname !== "/login" &&
      props.location.pathname !== "/register"  && props.location.pathname !== "/resetpassword" ? (
        <Footer />
      ) : (
        ""
      )}
    </>
  );
};

export default App;
