import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Image, Media } from "react-bootstrap";
import Icofont from "react-icofont";

class OrderCard extends React.Component {
  render() {
    return (
      <div className="bg-white card mb-4 order-list shadow-sm">
        <div className="gold-members p-4">
          <Media>
            {/* <Image className="mr-4" src={this.props.image} alt={this.props.imageAlt} /> */}
            <Media.Body>
              {this.props.paymentStatus == "APPROVED" ? (
                <span className="float-right text-success">
                  PAYMENT
                  <Icofont icon="check-circled" className="text-success ml-1" />
                </span>
              ) : (
                <span className="float-right text-warning">
                  PAYMENT
                  <Icofont icon="close-circled" className="text-warning ml-1" />
                </span>
              )}
              {this.props.paymentStatus == "APPROVED" ? (
                <h6 className="mb-2">
                  <Link to={this.props.detailLink} className="text-black">
                    {this.props.orderTitle}{` - Order Status: ${this.props.deliveredDate}`}
                  </Link>
                </h6>
              ) : (
                <h6 className="mb-2">
                  <Link to={this.props.detailLink} className="text-black">
                    {this.props.orderTitle}{` - Order Status: N/A`}
                  </Link>
                </h6>
              )}

              <p className="text-gray mb-1">
                <Icofont icon="location-arrow" /> {this.props.address && this.props.address.address_line_1 ? `${this.props.address.address_line_1}, ${this.props.address.address_line_2}` : 'PICKUP AT BAKERY'}
              </p>
              <p className="text-gray mb-3">
                <Icofont icon="list" /> ORDER #{this.props.orderNumber}
                <Icofont icon="clock-time" className="ml-2" />{" "}
                {this.props.orderDate}
              </p>
              <p className="text-dark">{this.props.orderProducts}</p>
              <hr />
              <div className="float-right">
                <Link
                  className="btn btn-sm btn-outline-primary mr-1"
                  to={this.props.helpLink}
                >
                  <Icofont icon="headphone-alt" /> HELP 
                </Link>
                {(this.props.paymentStatus == "APPROVED" || this.props.paymentMethod == "CASH_ON_DELIVERY") ? (
                  <Link
                    className="btn btn-sm btn-primary"
                    to={this.props.detailLink}
                  >
                    <Icofont icon="eye" /> VIEW
                  </Link>
                ) : (
                  ""
                )}
              </div>
              {this.props.paymentStatus == "APPROVED" ? (
                <p className="mb-0 text-black text-primary pt-2">
                  <span className="text-black font-weight-bold">
                    {" "}
                    Total Paid:
                  </span>{" "}
                  {this.props.orderTotal}
                  <p className="text-gray mb-1">
                    {this.props.paymentMethod && (<><Icofont icon="icofont-credit-card" /> {this.props.paymentMethod.replaceAll("_", " ")}</>)}
                  </p>
                </p>
              ) : (
                <p className="mb-0 text-black text-primary pt-2">
                  <span className="text-black font-weight-bold">
                    {" "}
                    Order Total:
                  </span>{" "}
                  {this.props.orderTotal}
                  <p className="text-gray mb-1">
                    {this.props.paymentMethod && (<><Icofont icon="icofont-credit-card" /> {this.props.paymentMethod.replaceAll("_", " ")}</>)}
                  </p>
                </p>
              )}
            </Media.Body>
          </Media>
        </div>
      </div>
    );
  }
}

OrderCard.propTypes = {
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  orderNumber: PropTypes.string.isRequired,
  orderDate: PropTypes.string.isRequired,
  deliveredDate: PropTypes.string,
  paymentStatus: PropTypes.string,
  orderTitle: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  orderProducts: PropTypes.string.isRequired,
  helpLink: PropTypes.string.isRequired,
  detailLink: PropTypes.string.isRequired,
  orderTotal: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string.isRequired,
};
export default OrderCard;
