import { BACKGROUND2, LOGO } from "assets/img";
import ErrorMessage from "components/common/ErrorMessage";
import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, withRouter } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userService } from "services";
import { userState } from "store/user";

const Register = ({ history }) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const setUser = useSetRecoilState(userState);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (values) => {
    try {
      const data = {
        name: values.name,
        email: values.email,
        password: values.password,
        confirmPassword: values.password,
        phone: values.phone,
      };
      setSubmitting(true);
      const {
        id,
        name,
        email,
        phone,
        token,
        updated_at: updatedAt,
        created_at: createdAt,
      } = await userService.register(data);
      setSubmitting(false);
      setUser({
        id,
        email,
        name,
        phone,
        token,
        createdAt,
        updatedAt,
      });
      history.push("/");
    } catch (error) {
      setSubmitting(false);
      // setError("Registration Failed");
      //   if (error.data) {
      //     const errors = error.data.errors;
      //     if (errors) {
      //       if (errors.email) {
      //         setError("email", { type: "server", message: errors.email });
      //       }
      //     }
      //   }

      //   setError();
    }
  };

  return (
    <Container fluid className="bg-white">
      <Row>
        <Col
          md={4}
          lg={6}
          className="d-none d-md-flex"
          style={{
            backgroundImage: `url('${BACKGROUND2}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Col md={8} lg={6}>
          <div className="login d-flex align-items-center py-5">
            <Container>
              <Link to="/">
                <div className="d-flex align-items-center">
                  <img
                    src={LOGO}
                    alt="logo"
                    style={{ width: 150, margin: "auto", paddingBottom: 40 }}
                  />
                </div>
              </Link>
              <Row>
                <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                  <h3 className="login-heading mb-4">
                    Welcome to Dine at Home...
                  </h3>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Alert
                      variant="danger"
                      show={Boolean(error)}
                      onClose={() => setError(null)}
                      dismissible
                    >
                      {error}
                    </Alert>
                    <div className="form-label-group">
                      <Form.Control
                        type="text"
                        id="name"
                        autoComplete="off" 
                        isInvalid={errors.name}
                        disabled={submitting}
                        placeholder="Name"
                        {...register("name", { required: "Name is required" })}
                      />
                      <Form.Label htmlFor="name">Name</Form.Label>
                      <ErrorMessage errors={errors} name="name" />
                    </div>
                    <div className="form-label-group">
                      <Form.Control
                        type="text"
                        id="phone"
                        autoComplete="off" 
                        isInvalid={errors.phone}
                        disabled={submitting}
                        placeholder="Phone Number"
                        {...register("phone", {
                          required: "Phone number is required",
                          minLength: {
                            value: 10,
                            message: "Phone number must be 10 charactors",
                          },
                          maxLength: {
                            value: 10,
                            message: "Phone number must be 10 charactors",
                          },
                        })}
                      />
                      <Form.Label htmlFor="phone">Phone Number</Form.Label>
                      <ErrorMessage errors={errors} name="phone" />
                    </div>
                    <div className="form-label-group">
                      <Form.Control
                        type="email"
                        id="email"
                        autoComplete="off" 
                        isInvalid={errors.email}
                        disabled={submitting}
                        placeholder="Email address"
                        {...register("email", {
                          required: "Email is required",
                          pattern:
                            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        })}
                      />
                      <Form.Label htmlFor="email">Email address</Form.Label>
                      <ErrorMessage errors={errors} name="email" />
                    </div>
                    <div className="form-label-group">
                      <Form.Control
                        type="password"
                        id="password"
                        autoComplete="off" 
                        placeholder="Password"
                        disabled={submitting}
                        isInvalid={errors.password}
                        {...register("password", {
                          required: "Password is required",
                        })}
                      />
                      <Form.Label htmlFor="password">Password</Form.Label>
                      <ErrorMessage errors={errors} name="password" />
                    </div>
                    {/* <div className="form-label-group">
                      <Form.Control
                        type="password"
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        {...register("confirmPassword")}
                      />
                      <Form.Label htmlFor="inputPassword">
                        Confirm Password
                      </Form.Label>
                    </div> */}
                    <Button
                      type="submit"
                      className="btn btn-lg  btn-block btn-login text-uppercase font-weight-bold mb-2"
                      style={{ color: "#fff" }}
                      disabled={submitting}
                    >
                      Sign Up
                    </Button>
                    <p>
                      By signing up you are agreeing to WATERS EDGE DINE AT HOME{" "}
                      <Link to="/terms-conditions">Terms & Conditions</Link>
                    </p>
                    <div className="text-center pt-3">
                      Already have an account?{" "}
                      <Link className="font-weight-bold" to="/login">
                        Sign In
                      </Link>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Register);
