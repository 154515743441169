import { atom } from "recoil";
export const cardOffersState = atom({
  key: "cardOfferState",
  default: [],
});

export const promocodesState = atom({
  key: "promocodesState",
  default: [],
});
