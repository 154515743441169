import ProductDetailModal from "components/modals/ProductDetailModal";
import PropTypes from "prop-types";
import React from "react";
import { Badge, Button, Image } from "react-bootstrap";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { productService } from "services";
import { useModalStore } from "store";
import { favoriteProdutsState, productsState } from "store/product";
import { authState } from "store/user";

const BestSeller = (props) => {
  const isAuthenticated = useRecoilValue(authState);
  const showModal = useModalStore((state) => state.showModal);
  const products = useRecoilValue(productsState);
  const [favories, setFavorite] = useRecoilState(favoriteProdutsState);

  const renderFavorite = () => {
    if (isAuthenticated) {
      return (
        <div
          className={`favourite-heart position-absolute ${props.favIcoIconColor}`}
        >
          {props.isFavorite ? (
            <Link to="#" onClick={() => handleRemoveFavorite(props.id)}>
              <Icofont
                icon="close"
                style={{ fontSize: 18, color: "#f15258" }}
              />
            </Link>
          ) : (
            <Link to="#" onClick={() => handleAddFavorite(props.id)}>
              <Icofont
                icon="heart"
                style={{ fontSize: 18, color: "#f15258" }}
              />
            </Link>
          )}
        </div>
      );
    }
    return null;
  };

  const handleClick = () => {
    const product = props.isFavorite ? favories[props.id] : products[props.id];
    showModal(<ProductDetailModal product={product} />);
  };

  const handleAddFavorite = async (productId) => {
    const product = products[productId];

    const productName = product.name;

    await productService.addFavoriteProduct(
      { product: productId },
      productName
    );
  };

  const handleRemoveFavorite = async (productId) => {
    const product = favories[productId];
    const productName = product.name;
    await productService.removeFavoriteProduct(
      product.favorite_id,
      productName
    );
    const clone = JSON.parse(JSON.stringify(favories));
    delete clone[productId];
    setFavorite({
      ...clone,
    });
  };

  return (
    <div
      className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="list-card-image">
        {/* {props.rating ? (
          <div className="star position-absolute">
            <Badge variant="success">
              <Icofont icon="star" /> {props.rating}
            </Badge>
          </div>
        ) : (
          ""
        )} */}
        {renderFavorite()}
        {props.showPromoted ? (
          <div className="member-plan position-absolute">
            <Badge variant={props.promotedVariant}>Promoted</Badge>
          </div>
        ) : (
          ""
        )}
        <Link to="#" onClick={handleClick}>
          <Image
            src={props.image}
            className={props.imageClass}
            alt={props.imageAlt}
            // style={{ width: "100%" }}
          />
        </Link>
      </div>
      <div
        className="p-3 position-relative"
        style={{ flex: 1 }}
        onClick={handleClick}
      >
        <div
          className="list-card-body"
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <h6 className="mb-1">
            <Link to="#" onClick={handleClick} className="text-black">
              {props.title}
            </Link>
          </h6>
          {props.subTitle ? (
            <div style={{ flex: 1, maxHeight: 60, overflowY: "hidden" }}>
              <p className="text-gray mb-3">{props.subTitle}</p>
            </div>
          ) : (
            ""
          )}
          {props.price ? (
            <p className="text-gray time mb-0">
              <Link className="btn btn-link btn-sm pl-0 text-black pr-0" to="#">
                {props.priceUnit}
                {"."} {props.price}{" "}
              </Link>
              {props.isNew ? (
                <Badge variant="success" className="ml-1">
                  NEW
                </Badge>
              ) : (
                ""
              )}

              <span className="float-right">
                <Button variant="primary" onClick={handleClick} size="sm">
                  ADD
                </Button>
              </span>
              {/* {quantity === 0 ? (
                <span className="float-right">
                  <Button
                    variant="outline-secondary"
                    onClick={IncrementItem}
                    size="sm"
                  >
                    ADD
                  </Button>
                </span>
              ) : (
                <span className="count-number float-right">
                  <Button
                    variant="outline-secondary"
                    onClick={DecreaseItem}
                    className="btn-sm left dec"
                  >
                    {" "}
                    <Icofont icon="minus" />{" "}
                  </Button>
                  <input
                    className="count-number-input"
                    type="text"
                    value={quantity}
                    readOnly
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={IncrementItem}
                    className="btn-sm right inc"
                  >
                    {" "}
                    <Icofont icon="icofont-plus" />{" "}
                  </Button>
                </span>
              )} */}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

BestSeller.propTypes = {
  title: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  isNew: PropTypes.bool,
  subTitle: PropTypes.string,
  price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string.isRequired,
  showPromoted: PropTypes.bool,
  promotedVariant: PropTypes.string,
  favIcoIconColor: PropTypes.string,
  rating: PropTypes.string,
  id: PropTypes.number.isRequired,
  qty: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  getValue: PropTypes.func.isRequired,
  isFavorite: PropTypes.bool,
};

BestSeller.defaultProps = {
  imageAlt: "",
  imageClass: "",
  isNew: false,
  subTitle: "",
  price: "",
  priceUnit: "Rs",
  showPromoted: false,
  promotedVariant: "dark",
  favIcoIconColor: "",
  rating: "",
  isFavorite: false,
};

export default BestSeller;
