import React from "react";
import { Link } from "react-router-dom";
import { Card, Media, Button } from "react-bootstrap";
import Icofont from "react-icofont";
import PropTypes from "prop-types";

const ChooseDeliveryOptionCard = (props) => {
  const selectedClassName = props.selected
    ? "border border-primary bg-selected"
    : "";

  const handleClick = () => {
    props.onDeliverHere(props.id);
  };

  return (
    <Card
      className={
        "bg-white addresses-item mb-4 " + props.boxClass + selectedClassName
      }
    >
      <div className="gold-members p-4">
        <Media>
          <div className="mr-3">
            <Icofont icon={props.icoIcon} className={props.iconclassName} />
          </div>
          <div className="media-body">
            <h6 className="mb-1 text-secondary">{props.title}</h6>
            <p className="mb-0 text-black font-weight-bold">
            <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={handleClick}
                  >
                    {" "}
                    SELECT{" "}
                  </Button>
            </p>
          </div>
        </Media>
      </div>
    </Card>
  );
};

ChooseDeliveryOptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  icoIcon: PropTypes.string.isRequired,
  iconclassName: PropTypes.string,
  onDeliverHere: PropTypes.func,
  onAddNewClick: PropTypes.func,
  selected: PropTypes.bool,
};

ChooseDeliveryOptionCard.defaultProps = {
  selected: false,
};

export default ChooseDeliveryOptionCard;
