import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

const ConfirmCardModal = ({ title, message, onHide, onConfirm }) => {
  return (
    <React.Fragment>
      <Modal.Header closeButton={true}>
        <Modal.Title as="h5" id="delete-address">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="text-black mb-3">1. {message}</p>

        <p className="mb-1 text-black">
          2. By confirming this you are agreeing to WATERS EDGE DINE AT HOME{" "}
          <Link to="/terms-conditions" target="_blank" >Terms & Conditions</Link>
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          onClick={onHide}
          variant="outline-primary"
          className="d-flex w-45 text-center justify-content-center"
        >
          CANCEL
        </Button>
        <Button
          type="button"
          variant="primary"
          className="d-flex w-45 text-center justify-content-center"
          onClick={onConfirm}
        >
          CONFIRM
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
};
export default ConfirmCardModal;
