import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

const ProductBox = (props) => {
  const handleClick = () => {
    props.onClick(props.id);
  };

  return (
    <div className={props.boxClass} onClick={handleClick}>
      <Link to={props.linkUrl}>
        <Image
          src={props.image}
          className={props.imageClass}
          alt={props.imageAlt}
        />
        {props.title ? <h6>{props.title}</h6> : ""}
        {props.counting ? <p>{props.counting}</p> : ""}
      </Link>
    </div>
  );
};

ProductBox.propTypes = {
  id: PropTypes.any,
  onClick: PropTypes.func,
  linkUrl: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  imageAlt: PropTypes.string,
  boxClass: PropTypes.string,
  title: PropTypes.string,
  counting: PropTypes.string,
};

ProductBox.defaultProps = {
  id: null,
  onClick: (f) => f,
  imageAlt: "",
  image: "",
  imageClass: "",
  linkUrl: "",
  boxClass: "products-box",
  title: "",
  counting: "",
};

export default ProductBox;
