import ErrorMessage from "components/common/ErrorMessage";
import { BACKGROUND1, LOGO } from "assets/img";
import qs from "qs-lite";
import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userService } from "services";
import { userState } from "store/user";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const ResetPassword = ({ history }) => {
  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState(null);

  const setUser = useSetRecoilState(userState);

  const [phonenumhide, setPhoneNumHide] = useState(false);

  const [otphide, setOtpHide] = useState(true);

  const [newpasshide, setNewPassHide] = useState(true);

  const [phonenumber, setphonenumber] = useState("");

  const [otpcode, setOtpCode] = useState("");

  const query = qs.parse(history.location.search.substring(1));

  const redirectPath = query.to ? query.to : "/";

  const onSubmitPhoneNumber = async (values) => {
    const data = {
      phone_number: values.phone,
    };

    //todo extra validations
    setSubmitting(true);
    try {
      await userService.generateOTP(data);
      setSubmitting(false);

      setPhoneNumHide(true);
      setOtpHide(false);

      setphonenumber(values.phone);
    } catch (err) {
      setSubmitting(false);
      toast("Somthing Wrong");
      //show error toast
    }
  };

  const onSubmitOtpCode = async (values) => {
    const data = {
      phone_number: phonenumber,
      otp: values.otp,
    };

    //todo extra validations
    setSubmitting(true);

    try {
      const response = await userService.verifyOTP(data);
      setSubmitting(false);

      if (response.data.otp_verified === false) {
        toast("OTP Verifi Faild");
      } else {
        setOtpHide(true);
        setNewPassHide(false);
        setOtpCode(values.otp);
        // toast("OTP Verifi Success");
      }
    } catch (err) {
      setSubmitting(false);
      toast("Somthing Wrong");
      //show error toast
    }
  };

  const onSubmitNewPassword = async (values) => {
    const data = {
      phone_number: phonenumber,
      otp: otpcode,
      password: values.password,
      confirm: values.confirm,
    };

    //todo extra validations
    setSubmitting(true);

    try {
      await userService.resetPassword(data);
      setSubmitting(false);
      history.push("/login");
    } catch (err) {
      setSubmitting(false);
      toast("Somthing Wrong");
      //show error toast
    }
  };

  return (
    <Container fluid className="bg-white">
      <Row>
        <Col
          md={4}
          lg={6}
          className="d-none d-md-flex"
          style={{
            backgroundImage: `url('${BACKGROUND1}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Col md={8} lg={6}>
          <div className="login d-flex align-items-center py-5">
            <Container>
              <Link to="/">
                <div className="d-flex align-items-center">
                  <img
                    src={LOGO}
                    alt="logo"
                    style={{ width: 150, margin: "auto", paddingBottom: 40 }}
                  />
                </div>
              </Link>
              <Row hidden={phonenumhide}>
                <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                  <Formik
                    initialValues={{
                      phone: "",
                    }}
                    validationSchema={Yup.object().shape({
                      phone: Yup.string().required("Required"),
                    })}
                    onSubmit={onSubmitPhoneNumber}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      touched,
                      values,
                      submitForm,
                      isValid,
                    }) => (
                      <div>
                        <h3 className="login-heading mb-4">Password Reset</h3>
                        <h6 className="login-heading mb-4">
                          Phone Number Verification
                        </h6>
                        <div className="mb-3">
                          <div className="input-group">
                            <input
                              name="phone"
                              type="number"
                              autoComplete="off" 
                              className="form-control"
                              id="phone_id"
                              placeholder="Phone Number"
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {touched.phone && errors.phone}
                          </div>
                        </div>

                        <button
                          className="btn btn-primary mt-4 d-block w-100"
                          type="button"
                          onClick={submitForm}
                        >
                          Get Code
                        </button>
                      </div>
                    )}
                  </Formik>
                </Col>
              </Row>
              <Row hidden={otphide}>
                <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                  <Formik
                    initialValues={{
                      otp: "",
                    }}
                    validationSchema={Yup.object().shape({
                      otp: Yup.string().required("Required"),
                    })}
                    onSubmit={onSubmitOtpCode}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      touched,
                      values,
                      submitForm,
                      isValid,
                    }) => (
                      <div>
                        <h6 className="login-heading mb-4">OTP Verification</h6>

                        <div className="input-group">
                          <input
                            name="otp"
                            type="number"
                            className="form-control"
                            id="otp_id"
                            autoComplete="off" 
                            placeholder="OTP"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {touched.otp && errors.otp}
                        </div>

                        <button
                          className="btn btn-primary mt-4 d-block w-100"
                          type="button"
                          onClick={submitForm}
                        >
                          Verify OTP
                        </button>
                      </div>
                    )}
                  </Formik>
                </Col>
              </Row>
              <Row hidden={newpasshide}>
                <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                  <Formik
                    initialValues={{
                      password: "",
                      confirm: "",
                    }}
                    validationSchema={Yup.object().shape({
                      password: Yup.string()
                        .required("Required")
                        .min(8, "password must have at least 8 characters"),
                      confirm: Yup.string().when("password", {
                        is: (val) => (val && val.length > 0 ? true : false),
                        then: Yup.string().oneOf(
                          [Yup.ref("password")],
                          "Both password need to be the same"
                        ),
                      }),
                    })}
                    onSubmit={onSubmitNewPassword}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      touched,
                      values,
                      submitForm,
                      isValid,
                    }) => (
                      <div>
                        <h6 className="login-heading mb-4">
                          Enter your new password
                        </h6>

                        <div className="input-group">
                          <input
                            name="password"
                            type="password"
                            autoComplete="off" 
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {touched.password && errors.password}
                        </div>

                        <div className="input-group mt-2">
                          <input
                            name="confirm"
                            type="password"
                            autoComplete="off" 
                            className="form-control"
                            id="confirm"
                            placeholder="Confirm Password"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {touched.confirm && errors.confirm}
                        </div>
                        {/* <div className="col-md-2" hidden={submitloading}>
                          <div className="spinner spinner-7">
                            <div className="bounce1" />
                            <div className="bounce2" />
                            <div className="bounce3" />
                          </div>
                        </div> */}
                        <button
                          className="btn btn-primary mt-4 d-block w-100"
                          type="button"
                          onClick={submitForm}
                        >
                          Update Password
                        </button>
                      </div>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(ResetPassword);
