import React, { useEffect, useState } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { getFavoriteProducts } from "services/product";
import { favoriteProdutsState } from "store/product";
import { STORAGE_PATH } from "constant";
import { PRODUCT } from "assets/img";
import BestSeller from "components/common/BestSeller";
import NoDataMessage from "components/common/NoDataMessage";
import { withRouter } from "react-router-dom";
import Loading from "components/common/Loading";

const Favourites = ({ history }) => {
  const [favorites, setFavories] = useRecoilState(favoriteProdutsState);
  const items = Object.values(favorites);
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    setLoading(true);
    const data = await getFavoriteProducts();
    if (data) {
      setLoading(false);
      setFavories(data[0]);
    }
  }

  const handleRedirect = () => {
    history.push("/");
  };

  const renderFavorites = () => {
    if (loading) {
      return <Loading />;
    }

    return items.map((product, index) => {
      const price =
        product.default_option.price + product.default_portion.price;
      const image =
        product.images.length > 0
          ? `${STORAGE_PATH}/${product.images[0].url}`
          : PRODUCT;
      return (
        <Col md={4} sm={6} className="pb-2" key={index}>
          <BestSeller
            id={product.id}
            title={product.name}
            subTitle={product.description}
            imageAlt="Product"
            image={image}
            imageClass="img-fixed"
            //   linkUrl="detail"
            offerText=""
            time="15–25 min"
            price={price}
            showPromoted={product.featured}
            promotedVariant="dark"
            favIcoIconColor="text-danger"
            qty={2}
            rating="3.1 (300+)"
            getValue={() => 1}
            isFavorite={true}
          />
        </Col>
      );
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="p-4 bg-white h-100 shadow-sm">
        <Row>
          <Col md={12}>
            <h4 className="font-weight-bold mt-0 mb-3">Favorites</h4>
          </Col>

          {renderFavorites()}
        </Row>
        {loading === false && items.length === 0 ? (
          <Row style={{ height: "100%" }}>
            <Col
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Row>
                <Col
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <NoDataMessage
                    message="No Favorites Added."
                    style={{ color: "#000" }}
                  />
                </Col>
                {/* <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Button onClick={handleRedirect}>VIEW MENU</Button>
                </Col> */}
              </Row>
            </Col>
          </Row>
        ) : null}
      </div>
    </>
  );
};
export default withRouter(Favourites);
