import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { Row, Col, Container, Image } from "react-bootstrap";
import Offers from "./myaccount/Offers";
import Orders from "./myaccount/Orders";
import Favourites from "./myaccount/Favourites";
import Payments from "./myaccount/Payments";
import Addresses from "./myaccount/Addresses";
import Settings from "./myaccount/Settings";
import { useRecoilValue } from "recoil";
import { userState } from "store/user";

const MyAccount = () => {
  const user = useRecoilValue(userState);

  return (
    <section className="section pt-4 pb-4 osahan-account-page">
      <Container>
        <Row>
          <Col md={3}>
            <div className="osahan-account-page-left shadow-sm bg-white h-100">
              <div className="border-bottom p-4">
                <div className="osahan-user text-center">
                  <div className="osahan-user-media">
                    <Image
                      className="mb-3 rounded-pill shadow-sm mt-1"
                      src="https://secure.gravatar.com/avatar/d6cdae91b2668c96b73831cc29da0bcb?s=60"
                      alt="gurdeep singh osahan"
                    />
                    <div className="osahan-user-media-body">
                      <h6
                        className="mb-2"
                        style={{ textTransform: "capitalize" }}
                      >
                        {user.name}
                      </h6>
                      <p className="mb-1">{user.phone}</p>
                      <p>{user.email}</p>
                      <p className="mb-0 text-black font-weight-bold">
                        <Link
                          to="/myaccount/settings"
                          className="text-primary mr-3"
                        >
                          <i className="icofont-ui-edit"></i> Edit in Settings
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="nav flex-column border-0 pt-4 pl-4 pb-4">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    exact
                    to="/myaccount/orders"
                  >
                    <i className="icofont-food-cart"></i> Orders
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    exact
                    to="/myaccount/offers"
                  >
                    <i className="icofont-sale-discount"></i> Offers
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    exact
                    to="/myaccount/favourites"
                  >
                    <i className="icofont-heart"></i> Favorites
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    exact
                    to="/myaccount/payments"
                  >
                    <i className="icofont-credit-card"></i> Cards
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    exact
                    to="/myaccount/addresses"
                  >
                    <i className="icofont-location-pin"></i> Addresses
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    exact
                    to="/myaccount/settings"
                  >
                    <i className="icofont-settings-alt"></i> Settings
                  </NavLink>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={9}>
            <Switch>
              <Route path="/myaccount/orders" exact component={Orders} />
              <Route path="/myaccount/offers" exact component={Offers} />
              <Route
                path="/myaccount/favourites"
                exact
                component={Favourites}
              />
              <Route path="/myaccount/payments" exact component={Payments} />
              <Route path="/myaccount/addresses" exact component={Addresses} />
              <Route path="/myaccount/settings" exact component={Settings} />
            </Switch>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MyAccount;
