import qs from "qs-lite";
import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { dishListState } from "store/dishes";

const Dishes = ({ history }) => {
  // const dishes = useRecoilValue(dishListState);

  const dishesIdFromQuery = useMemo(() => {
    const { dishes: type = "" } = qs.parse(
      history.location.search.substring(1)
    );

    return type;
  }, [history.location]);

  const handleChange = ({ target: { value } }) => {
    const query = qs.parse(history.location.search.substring(1));
    const updatedQuery = qs.stringify({ ...query, dishes: value });
    history.push({ pathname: "/menu", search: updatedQuery });
  };

  const dishes = [
    // { id: 0, name: "ANY" },
    { id: 1, name: "NON_VEGETARIAN" },
    { id: 2, name: "VEGETARIAN" },
  ];

  return (
    <div className="filters pt-2">
      <div className="filters-body rounded shadow-sm bg-white">
        <div className="filters-card p-4">
          <h6 className="text-left d-flex align-items-center p-0">
            Veg. / Non- Veg.
          </h6>
          <div>
            <div className="filters-card-body card-shop-filters pt-0">
              <Form>
                <Form.Check
                  custom
                  type="radio"
                  name="Dishes"
                  id="ANY_ID"
                  label="Any"
                  defaultChecked={!dishesIdFromQuery}
                  value={""}
                  onChange={handleChange}
                />
                {/* <Form.Check
                  key={1}
                  custom
                  type="radio"
                  name="NON_VEGETARIAN"
                  id="NON_VEGETARIAN_ID"
                  label="Non Vegetarian"
                  // defaultChecked={dishesIdFromQuery == "NON_VEGETARIAN"}
                  value={"NON_VEGETARIAN"}
                  onChange={handleChange}
                /> */}
                {dishes.map((subcat, index) => (
                  <Form.Check
                    key={index}
                    custom
                    type="radio"
                    name="Dishes"
                    defaultChecked={dishesIdFromQuery == subcat.id}
                    id={subcat.name}
                    label={subcat.name}
                    value={subcat.name}
                    onChange={handleChange}
                  />
                ))}

                {/* <Form.Check
                  key={3}
                  custom
                  type="radio"
                  name="VEGETARIAN"
                  id="VEGETARIAN_ID"
                  label="Vegetarian"
                  defaultChecked={dishesIdFromQuery == "VEGETARIAN"}
                  value={"VEGETARIAN"}
                  onChange={handleChange}
                /> */}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Dishes);
