import qs from "qs-lite";
import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { categoriesListState } from "store/category";

const Categories = ({ history }) => {
  const categories = useRecoilValue(categoriesListState);

  const categoryIdFromQuery = useMemo(() => {
    const { cuisine: categoryId = "" } = qs.parse(
      history.location.search.substring(1)
    );

    return categoryId;
  }, [history.location]);

  const handleChange = ({ target: { value } }) => {
    const query = qs.parse(history.location.search.substring(1));
    const updatedQuery = qs.stringify({ ...query, cuisine: value });
    history.push({ pathname: "/menu", search: updatedQuery });
  };

  return (
    <div className="filters pt-2">
      <div className="filters-body rounded shadow-sm bg-white">
        <div className="filters-card p-4">
          <h6 className="text-left d-flex align-items-center p-0">
            Filter by Cuisine
          </h6>
          <div>
            <div className="filters-card-body card-shop-filters pt-0">
              <Form>
                <Form.Check
                  custom
                  type="radio"
                  name="category"
                  id="defaultCategory"
                  label="Any"
                  defaultChecked={!categoryIdFromQuery}
                  value={""}
                  onChange={handleChange}
                />
                {categories.map((category, index) => (
                  <Form.Check
                    key={index}
                    custom
                    type="radio"
                    name="category"
                    id={category.name}
                    label={category.name}
                    value={category.id}
                    onChange={handleChange}
                    defaultChecked={categoryIdFromQuery == category.id}
                  />
                ))}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Categories);
