import React from "react";
import PropTypes from "prop-types";
import { Image, Button } from "react-bootstrap";

const MayLikeItem = (props) => {
  const handleClick = () => {
    props.onAdd(props.id);
  };

  return (
    <div className={"position-relative " + props.boxClass}>
      <Button
        onClick={handleClick}
        className="btn btn-primary btn-sm position-absolute"
      >
        ADD
      </Button>
      <Image
        src={props.image}
        className={props.imageClass}
        alt={props.imageAlt}
      />
      {props.title ? (
        <h6
          style={{
            minHeight: 47,
            maxHeight: 47,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {props.title}
        </h6>
      ) : (
        ""
      )}
      {props.price ? <small>{props.price}</small> : ""}
    </div>
  );
};

MayLikeItem.propTypes = {
  onClick: PropTypes.func,
  onAdd: PropTypes.func,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  imageAlt: PropTypes.string,
  boxClass: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.string,
  id: PropTypes.any,
};

MayLikeItem.defaultProps = {
  imageAlt: "",
  image: "",
  imageClass: "",
  boxClass: "mall-category-item",
  title: "",
  price: "",
};

export default MayLikeItem;
