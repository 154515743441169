import React, { useState, useRef } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ErrorMessage from "components/common/ErrorMessage";
import { useForm } from "react-hook-form";
import Icofont from "react-icofont";
import { userService } from "services";
import { userState } from "store/user";
import { useRecoilState } from "recoil";

const UpdateUserDetails = () => {
  const [updatingUserDetails, setUpdatingUserDetails] = useState(false);
  const [user, setUser] = useRecoilState(userState);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { name: user.name, email: user.email, phone: user.phone },
  });

  const onSubmit = async (values) => {
    try {
      setUpdatingUserDetails(true);
      const {
        id,
        name,
        email,
        phone,
        updated_at: updatedAt,
        created_at: createdAt,
      } = await userService.updateDetails(values);

      setUser({
        id,
        email,
        name,
        phone,
        token: user.token,
        createdAt,
        updatedAt,
      });
    } catch (error) {
    } finally {
      setUpdatingUserDetails(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <Form.Group className="col-md-12">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Name"
            isInvalid={errors.name}
            disabled={updatingUserDetails}
            {...register("name", {
              required: "Name is required",
              minLength: {
                value: 3,
                message: "Name must be at least 3 charactors",
              },
              maxLength: {
                value: 200,
                message: "Name must not exeed 200 charactors",
              },
            })}
          />
          <ErrorMessage errors={errors} name="name" />
        </Form.Group>
        <Form.Group className="col-md-12">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Phone Number"
            isInvalid={errors.phone}
            disabled={updatingUserDetails}
            {...register("phone", {
              required: "Phone number is required",
              minLength: {
                value: 10,
                message: "Phone number must be 10 charactors",
              },
              maxLength: {
                value: 10,
                message: "Phone number must be 10 charactors",
              },
            })}
          />
          <ErrorMessage errors={errors} name="phone" />
        </Form.Group>
        <Form.Group className="col-md-12">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email"
            isInvalid={errors.email}
            disabled={updatingUserDetails}
            {...register("email", {
              required: "Email is required",
              pattern: {
                value:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Invalid email",
              },
            })}
          />
          <ErrorMessage errors={errors} name="email" />
        </Form.Group>
        <Form.Group
          className="col-md-12"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button type="submit" disabled={updatingUserDetails}>
            Update
          </Button>
        </Form.Group>
      </div>
    </Form>
  );
};

const UpdatePassword = () => {
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const password = useRef();
  password.current = watch("password");

  const onSubmit = (values) => {
    try {
      setUpdatingPassword(true);
      userService.changePassword(values);
      reset();
    } catch (error) {
    } finally {
      setUpdatingPassword(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <Form.Group className="col-md-12">
          <Form.Label>Current Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Current Password"
            isInvalid={errors.currentPassword}
            disabled={updatingPassword}
            {...register("currentPassword", {
              required: "Current Password is Required",
            })}
          />
          <ErrorMessage errors={errors} name="currentPassword" />
        </Form.Group>
        <Form.Group className="col-md-12">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="New Password"
            isInvalid={errors.password}
            disabled={updatingPassword}
            {...register("password", {
              required: "Password is Required",
            })}
          />
          <ErrorMessage errors={errors} name="password" />
        </Form.Group>
        <Form.Group className="col-md-12">
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm New Password"
            isInvalid={errors.confirm}
            disabled={updatingPassword}
            {...register("confirm", {
              required: "Confirm Password is Required",
              validate: (value) =>
                value === password.current || "The Passwords Does Not Match",
            })}
          />
          <ErrorMessage errors={errors} name="confirm" />
        </Form.Group>
        <Form.Group
          className="col-md-12"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button type="submit">Update</Button>
        </Form.Group>
      </div>
    </Form>
  );
};

const Settings = () => {
  return (
    <>
      <div className="p-4 bg-white h-100 shadow-sm">
        <Row>
          <Col md={12}>
            <h4 className="font-weight-bold mt-0 mb-3">Settings</h4>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h6 className="font-weight-bold mt-0 mb-3">User Details</h6>
          </Col>
          <Col md={12}>
            <UpdateUserDetails />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h6 className="font-weight-bold mt-2 mb-3">Change Password</h6>
          </Col>

          <Col md={12}>
            <UpdatePassword />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default withRouter(Settings);
