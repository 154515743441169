import { PRODUCT } from "assets/img";
import { STORAGE_PATH } from "constant";
import qs from "qs-lite";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { withRouter } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { productService } from "services";
import {
  productByOrderState,
  productNameFilterState,
  productPaginationState,
  productSortFilterState,
  productsState,
} from "store/product";
import BestSeller from "./common/BestSeller";
import Loading from "./common/Loading";
import NoDataMessage from "./common/NoDataMessage";

const Products = ({ history }) => {
  const PRODUCT_LIMIT_PER_PAGE = 12;
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useRecoilState(productsState);
  const [productOrder, setProductOrder] = useRecoilState(productByOrderState);
  const [pagination, setPagination] = useRecoilState(productPaginationState);

  const categoryAndSubCategoryFilter = useMemo(() => {
    const query = qs.parse(history.location.search.substring(1));

    const {
      cuisine: categoryId = "",
      outlet: subCategoryId = "",
      dishes: type = "",
    } = query;
    const categoryFilter = { categoryId };
    const subCategoryFilter = { subCategoryId };
    const dishes = { type };
    return [categoryFilter, subCategoryFilter, dishes];
  }, [history.location]);

  const productNameFilter = useRecoilValue(productNameFilterState);
  const productSortFilter = useRecoilValue(productSortFilterState);

  const nameFilter = useMemo(() => {
    return productNameFilter.length > 2 ? productNameFilter : "";
  }, [productNameFilter]);

  const fetchProducts = async () => {
    const data = await productService.getProducts({
      filter: [...categoryAndSubCategoryFilter, { name: nameFilter }, { status: "ACTIVE" }],
      // limit: PRODUCT_LIMIT_PER_PAGE,
      // skip: currentPage * PRODUCT_LIMIT_PER_PAGE,
      ...productSortFilter,
    });

    return data;
  };

  async function loadMoreData() {
    try {
      if (currentPage === 0) {
        setCurrentPage(currentPage + 1);
        return true;
      } else {
        setLoading(true);
        const data = await fetchProducts();
        setProducts({ ...products, ...data[0] });
        setProductOrder([...productOrder, ...data[1]]);
        setPagination(data[2]);
        setCurrentPage(currentPage + 1);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function fetchData() {
    try {
      setLoading(true);
      const data = await fetchProducts();
      setProducts(data[0]);
      setProductOrder(data[1]);
      setPagination(data[2]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const hasNextPage = pagination.limit === pagination.count;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMoreData,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: undefined,
    rootMargin: "0px 0px 400px 0px",
  });

  const rendeNoDataMessage = () => {
    if (!loading) {
      return (
        <Col
          style={{ display: "flex", justifyContent: "center", marginTop: 100 }}
        >
          <NoDataMessage message="Sorry. We didn't find dishes for your query..." />
        </Col>
      );
    }

    return null;
  };
  useEffect(() => {
    setCurrentPage(0);
    fetchData();
  }, [categoryAndSubCategoryFilter, nameFilter, productSortFilter]);

  return (
    <React.Fragment>
      <Row id="scrollableDiv">
        {productOrder.length > 0
          ? productOrder.map((productId, index) => {
              const product = products[productId];
              const price =
                product.default_option.price + product.default_portion.price;
              const image =
                product.images.length > 0
                  ? `${STORAGE_PATH}/${product.images[0].url}`
                  : PRODUCT;
              return (
                <Col lg={4} md={6} sm={6} className="pb-2" key={index}>
                  <BestSeller
                    id={product.id}
                    title={product.name}
                    subTitle={product.description}
                    imageAlt="Product"
                    image={image}
                    imageClass="img-fixed"
                    offerText=""
                    time="15–25 min"
                    price={price}
                    showPromoted={product.featured}
                    promotedVariant="dark"
                    favIcoIconColor="text-danger"
                    rating="3.1 (300+)"
                    getValue={() => 1}
                  />
                </Col>
              );
            })
          : rendeNoDataMessage()}

        {(loading || hasNextPage) && (
          <Col
            ref={sentryRef}
            sm={12}
            className="text-center load-more"
            style={{
              display: "flex",
              alignItems: "center",
              justify: "center",
            }}
          >
            <Loading />
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default withRouter(Products);
